<template>
	<div id="top">
		<div class="grzx_tbbg">
			<div class="container">
				<div class="grzx_logo">
					<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/lucency-Logo.png" @click="toIndex">
					<span class="head-logo-title">资源方用户中心</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		methods:{
			toIndex(){
				this.$router.push({
					path:'/'
				})
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/achievement.less">
