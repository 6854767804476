<template>
	<div id="add">
		<div class="content">
			<el-page-header @back="goBack" content="新闻发布"></el-page-header>
			<div class="garr-container">
				<div class="content-title">
					<el-input type="textarea" autosize v-model="form.title" :maxlength="maxlength"
						placeholder="请输入文章标题(5-30个字)" max="30"></el-input>

					<span class="title-tip" v-if="form.title.length > 5">
						<span class="cur">{{ form.title.length }}</span>
						/ 30
					</span>
					<span class="title-tip" v-else>
						还需输入
						<span class="publish-title-bold">5</span>
						个字
					</span>
				</div>
				<vue-editor v-model="form.content" @focus="onEditorFocus" use-custom-image-handler
					@image-added="handleImageAdded" @blur="onEditorBlur" @selection-change="onSelectionChange" />
				<el-form ref="ruleForm" :model="form" label-width="120px" label-position="left" :rules="rules">
					<el-form-item label="展示封面" prop="imageUrl">
						<el-upload :action="baseUrl + 'file/upload_one'" list-type="picture-card"
							:on-success="cover_success" :on-remove="handleRemove" :on-change="handleChange"
							:limit="limit" :data="{ dir: 'news' }" :file-list="cover_list"
							:class="{ disabled: uploadDisabled }">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible">
							<el-image style="width: 150px; height: 105px" :src="dialogImageUrl" fit="cover"></el-image>
						</el-dialog>
					</el-form-item>
					<el-form-item label="原创">
						<el-switch v-model="form.is_original"></el-switch>
					</el-form-item>
				</el-form>
			</div>
			<div class="publish-footer">
				<div class="garr-footer-publish-content publish-footer-content">
					<div class="footer-tip">
						正文字数：0
						<span class="footer-tip-save">已保存</span>
					</div>
					<div class="footer-back-content">
						回到顶部
						<i class="el-icon-arrow-up"></i>
					</div>
					<button class="byte-btn" @click="caogao()"><span>存草稿</span></button>
					<button class="byte-btn" @click="submitForm('ruleForm')"><span>发布</span></button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		VueEditor
	} from 'vue2-editor';
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			VueEditor
		},
		data() {
			return {
				form: {
					title: '',
					content: '',
					cover: [],
					is_original: 0
				},
				cover_list: [],
				rules: {
					cover: [{
						required: true,
						message: '请输上传封面'
					}]
				},
				maxlength: 30,
				dialogImageUrl: '',
				dialogVisible: false,
				limit: 4,
				uploadDisabled: false,
				id: ''
			};
		},
		mounted() {
			let query = this.$route.query;
			if (typeof query.id != 'undefined') {
				this.id = query.id;
				this.get_detail();
			}
			if (localStorage.getItem('news' + this.id) != null) {
				this.form = JSON.parse(localStorage.getItem('news' + this.id));
				console.log(this.form);
				let that = this;
				this.form.cover.forEach(function(v) {
					that.cover_list.push({
						url: v
					});
				});
			}
			this.company_detail();
			// if (localStorage.getItem('news' + this.id + 'cover_list') != null) {
			// 	this.cover_list = JSON.parse(localStorage.getItem('news' + this.id + 'cover_list'));
			// 	console.log(this.cover_list);
			// }
		},
		methods: {
			company_detail() {
				this.$http_qm
					.post('company/detail', {
						type: 'me'
					}).then(res => {
						if (res.code == 200) {
							this.form.company_id = res.data.id;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			handleImageAdded(file, Editor, cursorLocation, resetUploader) {
				console.log(file);
				var formData = new FormData();
				formData.append('file', file);
				formData.append('dir', 'news');
				this.$http
					.post('file/upload_one', formData, {
						headers: {
							"Content-Type": "multipart/form-data;"
						}
					})
					.then(res => {
						if (res.code == 200) {
							let url = res.data.img_url; // Get url from response
							Editor.insertEmbed(cursorLocation, 'image', url);
							resetUploader();
						} else {
							this.$message.error(res.msg)
						}
					})
					.catch(function(error) {
						this.$message.info("图片超过最大值6M 或者 图片格式错误")
						console.log(error);
					});
			},
			caogao() {
				localStorage.setItem('news' + this.id, JSON.stringify(this.form));
				// localStorage.setItem('news' + this.id + 'cover_list', JSON.stringify(this.cover_list));
				this.$message.info('草稿已保存');
			},
			get_detail() {
				this.$http_qm
					.post('news/detail', {
						id: this.id
					})
					.then(res => {
						this.form = res.data;
						let cover_list = this.cover_list;
						this.form.cover.forEach(function(v) {
							cover_list.push({
								url: v
							});
						});
					})
					.catch(err => {
						console.log(err);
					});
			},
			cover_success(res) {
				if (res.code == 200) {
					this.form.cover.push(res.data.img_url);
				}
			},
			// 编辑器
			onEditorBlur(quill) {
				console.log('editor blur!', quill);
			},
			onEditorFocus(quill) {
				console.log('editor focus!', quill);
			},
			onSelectionChange(range) {
				console.log('selection change!', range);
			},
			// 上传
			handleChange(file, fileList) {
				if (fileList.length >= 3) {
					this.uploadDisabled = true;
				}
			},
			handleRemove(file, fileList) {
				console.log(file);
				if (fileList.length <= 1) {
					this.uploadDisabled = false;
				}
				let cover = [];
				fileList.forEach(function(v) {
					cover.push(v.url);
				});
				this.form.cover = cover;
				this.remove(file.url);
			},
			remove(path) {
				this.$http_qm
					.post('upload/remove', {
						path: path
					})
					.then(res => {
						this.$message.info(res.msg);
					})
					.catch(err => {
						console.log(err);
					});
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			submitForm(formName) {
				let formData = this.form;
				this.$refs[formName].validate(valid => {
					if (valid == '') {
						return false;
					}
					if (formData.title == '') {
						this.$message.error('请上传标题');
						return false;
					}
					if (formData.introduction == '') {
						this.$message.error('请填写新闻内容');
						return false;
					}
					if (this.form.is_original) {
						this.form.is_original = 1;
					} else {
						this.form.is_original = 0;
					}
					if (this.id != '') {
						var url = 'news/update';
					} else {
						url = 'news/add';
					}
					this.$http_qm
						.post(url, this.form)
						.then(res => {
							this.$message.info(res.msg);
							if (res.code == 200) {
								this.$router.push({
									path: '/news/admin'
								});
							}
						})
						.catch(err => {
							console.log(err);
						});
				});
			},
			goBack() {
				this.$router.go(-1);
			}
		}
	};
</script>

<style lang="less">
	#add {
		width: 100%;
		min-width: 808px;
		overflow-x: hidden;
		background-color: #f8f8f8;

		.content {
			margin: 0 auto;
			width: 100%;
			min-width: 808px;
			overflow-y: auto;

			.el-page-header {
				width: 974px;
				margin: 84px auto 0;
			}

			.garr-container {
				width: 846px;
				margin: 24px auto 94px;
				position: relative;
				border-radius: 4px;
				background-color: #fff;
				padding: 32px 64px;

				.content-title {
					padding: 0 0 25px;
					display: flex;
					flex-direction: row;
					margin: 0 0 20px;
					border-bottom: 1px solid rgb(232, 232, 232);

					.el-textarea__inner {
						position: relative;
						z-index: 2;
						width: 680px;
						border: 0;
						border-radius: 0;
						min-height: 36px;
						font-weight: 700;
						font-size: 24px;
					}

					.title-tip {
						width: 100%;
						font-size: 12px;
						line-height: 40px;
						color: #999;
						z-index: 1;

						.publish-title-bold {
							color: #000;
						}
					}
				}

				.ql-snow {
					border-left: none !important;
					border-right: none !important;
					border-top: none !important;
				}

				.ql-editor {
					min-height: 500px;
				}

				.ql-toolbar {
					position: fixed;
					top: 0;
					width: 100%;
					left: 0;
					right: 0;
					display: flex;
					justify-content: center;
					background-color: #fafafa;
					border-bottom: 1px solid #e8e8e8;
					z-index: 999;

					button {
						width: 34px;
						height: 34px;

						svg {
							width: 28px;
							height: 28px;
						}
					}
				}

				.el-form {
					padding-top: 45px;
					border-top: 1px solid rgb(232, 232, 232);
				}

				.avatar-uploader .el-upload {
					border: 1px dashed #d9d9d9;
					border-radius: 6px;
					cursor: pointer;
					position: relative;
					overflow: hidden;
				}

				.avatar-uploader .el-upload:hover {
					border-color: #409eff;
				}

				.avatar-uploader-icon {
					font-size: 28px;
					color: #8c939d;
					width: 150px;
					height: 105px;
					line-height: 105px;
					text-align: center;
					background-color: #fafafa;
				}

				.avatar {
					width: 150px;
					height: 105px;
					display: block;
				}

				.el-upload--picture-card {
					width: 150px;
					height: 105px;
					line-height: 105px;
				}

				.el-upload-list__item {
					width: 150px;
					height: 105px;
					line-height: 105px;
				}

				.disabled {
					.el-upload--picture-card {
						display: none !important;
					}
				}
			}

			.publish-footer {
				position: fixed;
				left: 0;
				right: 0;
				bottom: 0;
				height: 68px;
				width: 100vw;
				line-height: 68px;
				z-index: 100;
				background-color: #fff;
				text-align: right;
				border-top: 1px solid #e8e8e8;

				.publish-footer-content {
					height: 100%;
					width: 816px;
					margin: 0 auto;

					.footer-tip {
						font-size: 12px;
						color: #9e9e9e;
						float: left;
						height: 100%;
						vertical-align: middle;
						line-height: 68px;
						margin-right: 20px;

						.footer-tip-save {
							margin-left: 20px;
							display: inline-block;
							width: 3em;
							white-space: nowrap;
						}
					}

					.footer-back-content {
						position: relative;
						margin-right: 15px;
						font-size: 12px;
						cursor: pointer;
						float: left;
					}

					.byte-btn {
						display: inline-block;
						margin-right: 20px;
						background-color: #fff;
						border: 1px solid #d8d8d8;
						color: #000066;
						font-size: 14px;
						padding: 0 20px;
						height: 36px;
						line-height: 34px;
						position: relative;
						outline: none;
						border-radius: 4px;
						cursor: pointer;
						white-space: nowrap;
						transition: all 0.3s;
						box-sizing: border-box;
					}
				}
			}
		}
	}
</style>
