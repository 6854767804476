<template>
	<div id="collges_service">
		<div class="banner">
			<h1>
				科院服务
			</h1>
			<h2>
				链接企业与科研院所，推动产业化落地
			</h2>
		</div>
		<div class="container">
			<div class="box1 box flex space-between">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/service/colleges/img4.png">
				<div class="col2">
					<div class="title">科技成果转化大会</div>
					<div class="content">
						每年定期举办科技牛成果转化大会，<br />
						为专家提供成果转化的平台<br />
						通过企业市场特征将成果传化为生产力<br />
						建立研发者和企业的坚固关系<br />
						促进和帮助企业实现技术创新<br />
						发现高校设立的科技成果转化机构实施转化

					</div>

				</div>
			</div>

			<div class="box3 box flex space-between">
				<div class="col1">
					<div class="title">
						企业需求精准推送
					</div>
					<div class="content">
						明确画像精准推送企业需求 <br>
						帮助高校院所打造开放性的成果转化平台<br>
						大数据分析加快高校科院科技成果转化率<br>
						
						充分发挥高校院所的力量 让研究内容实现产业化
					</div>


				</div>
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/service/colleges/img2.png">
			</div>
			<div class="box4 box flex space-between">
				<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/service/colleges/img3.png">
				<div class="col2">
					<div class="title">产学研项目合作</div>
					<div class="content">
						解决各种生产要素促进技术创新<br>
						通过专门机构实施科技成果转化<br>
						解决研发过程中资金不足问题<br>
						为高校院所提供全链式的创新平台<br>
						可以跟企业深度合作 建立新的研发产业链
						</div>

				</div>
			</div>

		</div>
		<div class="box5">
			<div class="container">
				<div class="row1">
					<div class="row2">

						<div class="text1">
							我想咨询
						</div>
						<div class="text2">
							留下您的联系方式，我们会第一时间与您联系
						</div>
						<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
						<el-input v-model.number="form.tel" placeholder="请输入您的联系方式"></el-input>
						<el-button plain @click="submit()">提交</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '科技牛—企业专业服务—— 提升企业创新力量与产品核心竞争力',
			meta: [{
					name: 'keywords',
					content: '企业创新，技术对接，技改支持，技术创新服务'
				},
				{
					name: 'description',
					content: '科技牛技术创新顾问为企业提供一站式对接服务，链接专家成果，助力企业创新。'
				}
			]
		},
		data() {
			return {
				form: {
					name: '',
					tel: ''
				}
			}
		},
		methods: {
			submit() {
				if (this.form.name == "") {
					this.$message.error('请输入您的姓名');
					return false
				}
				if (this.form.tel == "") {
					this.$message.error('请输入您的联系方式');
					return false
				}
				this.form.tel = this.form.tel.toString();
				this.$http_qm.post('form/add', {
					type: 8,
					info: this.form
				}).then(res => {
					if (res.code == 200) {
						this.form.tel = '';
						this.form.name = '';
						this.$message.success('提交成功');
					}else{
						this.$message.info(res.msg);
					}
				}).catch(err => {
					console.log(err)
				})
			},
			go_btn() {
				window.open(
					'https://p.qiao.baidu.com/cps/chat?siteId=16956657&userId=32242741&siteToken=eadf366274507def6435288099c80348'
				);
				/* document.querySelector('.box5').scrollIntoView({
					behavior: "smooth",
				}); */
			}
		}
	}
</script>

<style lang="less">
	@font-face {
		font-family: "Source-Han-Sans";
		src: url(../../assets/font/SourceHanSansCN-Normal.ttf);
	}

	#collges_service {
		font-family: "Source-Han-Sans";
		width: 100%;
		min-width: 1400px;

		.container {
			width: 1068px;
			margin: auto;
		}

		.box5 {
			height: 529px;
			margin-top: 57px;
			background: url(../../assets/img/service/government/img7.jpg) center center no-repeat;
			text-align: center;
			min-width: 1400px;

			.row1 {
				width: 50%;
			}

			.row2 {
				width: 480px;
				float: right;
				display: flex;
				flex-direction: column;
				margin: 76px 0px 0px 0px;
			}

			.text1 {
				font-size: 32px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 71px;
			}

			.text2 {
				font-size: 24px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #FFFFFF;
			}

			.form {
				width: 380px;
				margin: auto;

			}

			button {
				margin: 37px auto 0px;
				width: 160px;
			}

			.el-input {
				margin: 43px auto 0px;
				width: 380px;
			}

			input {}
		}

		.box4 {
			img {
				margin-right: 32px;
			}

			.col2 {
				padding-top: 67px;
			}
		}

		.box3 {
			img {
				margin-left: 32px;
			}
		}

		.box1 {
			margin-top: 40px;

			img {
				margin-right: 58px;
			}

			.btn {
				float: right;
			}

			.col2 {
				margin-top: 40px;
			}
		}

		.box {
			margin-top: 77px;

			& .title {
				font-size: 32px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #333333;
				line-height: 71px;
			}

			& .content {
				font-size: 24px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #333333;
				line-height: 42px;
			}

			.btn {
				cursor: pointer;
				width: 240px;
				height: 52px;
				line-height: 52px;
				background: #6299FF;
				box-shadow: -2px 5px 14px 2px rgba(59, 137, 255, 0.22);
				border-radius: 26px;
				font-size: 20px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				margin-top: 44px;
			}
		}

		.banner {
			height: 900px;
			background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/service/colleges/img1.jpg) center center no-repeat;
			text-align: center;
			min-width: 1400px;

			h1 {
				font-size: 52px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 71px;
				padding-top: 280px;
			}

			h2 {
				margin-top: 62px;
				font-size: 42px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 71px;
			}
		}
	}
</style>
