<template>
	<div id="admin">

		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>

			<div class="main_right transition-box">
				<transition name="el-zoom-in-center">
					<div class="page" v-if="show">
						<div class="page_nav">
							<div class="page_nav_tit">需求管理</div>
							<!-- <div class="page_nav_tit_desc">
							您已发布的产品总数为： <span class="highlight">7</span> 条
						</div> -->
						</div>
						<div class="page_list">
							<el-tabs type="card" @tab-click="handleClick">
								<el-tab-pane :label="item.title" v-for="(item,index) in dataList" :status="item.status"
									:key="index">
									<div class="listbox">
										<el-form :inline="true" :model="formInline" class="demo-form-inline"
											v-if="index==0">
											<el-form-item label="搜索:">
												<el-input size="small" v-model="demand.post.keyword"
													placeholder="请输入关键词"></el-input>
											</el-form-item>
											<!-- 	<el-form-item label="发布时间">
											
										</el-form-item> -->
											<el-form-item>
												<el-button size="small" type="primary" @click="onSubmit">查询</el-button>
											</el-form-item>
										</el-form>

										<el-table ref="multipleTable" :data="demand.list" tooltip-effect="dark"
											style="width: 100%" @selection-change="handleSelectionChange">
											<el-table-column type="selection" width="55"></el-table-column>
											<el-table-column label="需求图片" width="120">
												<template slot-scope="scope">
													<el-image style="width: 93px; height: 75px"
														:src="scope.row.cover[0]" fit="cover"></el-image>
												</template>
											</el-table-column>
											<el-table-column label="需求信息" show-overflow-tooltip>
												<template slot-scope="scope">
													<span class="product_title">{{scope.row.title}}</span>
													<span class="product_classes">分类：<b
															v-if="scope.row.demand_type">{{scope.row.demand_type}}</b></span>
													<span class="product_data">更新时间：{{scope.row.updated_at}}</span>
												</template>
											</el-table-column>
											<el-table-column align="center" label="征集方案列表">
												<template slot-scope="scope">
													<el-button plain size="mini" type="primary"
														@click="handleChech(scope.row.id)">查看</el-button>
												</template>
											</el-table-column>

											<el-table-column prop="status_mes" label="状态" width="100">
											</el-table-column>
											<el-table-column align="center" label="操作">
												<template slot-scope="scope">

													<el-button size="mini" type="primary" v-if="scope.row.status==1"
														@click="handleEdit(scope.$index, scope.row.id)">
														查看
													</el-button>
													<el-button size="mini" type="primary" v-else
														@click="handleEdit(scope.$index, scope.row.id)">
														编辑
													</el-button>
													<el-button size="mini" type="warning"
														@click="handleAedit(scope.$index, scope.row.id)">重发</el-button>
													<el-button size="mini" type="danger"
														@click="handleDelete(scope.$index, scope.row.id)">删除</el-button>
												</template>
											</el-table-column>
										</el-table>
										<div style="margin-top: 20px">
											<el-button size="small" @click="toggleDel()">删除</el-button>
											<el-button size="small" @click="toggleSelection()">取消选择</el-button>
										</div>
										<div class="product_page">
											<el-pagination @current-change="handleCurrentChange"
												:page-size="demand.post.limit" layout="total, prev, pager, next"
												:total="demand.count">
											</el-pagination>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
						</div>
					</div>
				</transition>
				<transition name="el-zoom-in-center">
					<div class="schemePage" v-if="show == false">
						<el-page-header @back="show = true" content="需求方案">
						</el-page-header>
						<el-tabs v-model="activeName" @tab-click="needClick">
							<el-tab-pane v-for="(v,k) in tabItem" :key="k" :status="v.status" :disabled="k == 1"
								:label="v.label" :name="v.name">
								<div class="tabs-contents">
									<el-table v-if="scheme.list" :data="scheme.list" style="width: 100%">
										<el-table-column type="expand">
											<template slot-scope="props">
												<el-form label-position="left" inline class="demo-table-expand">

													<el-form-item label="姓名">
														<span>{{ props.row.name }}</span>
													</el-form-item>
													<el-form-item label="电话">
														<span>{{ props.row.tel }}</span>
													</el-form-item>
													<el-form-item label="时间">
														<span>{{ props.row.created_at }}</span>
													</el-form-item>
													<el-form-item label="方案描述" class="formdesc">
														<span>{{ props.row.describe }}</span>
													</el-form-item>
													<el-form-item label="附件">
														<div v-for="(item,index) in props.row.annex" :key="index">
															<el-link type="primary" :href="item" target="_blank">点击查看
															</el-link>
														</div>
													</el-form-item>
												</el-form>
											</template>
										</el-table-column>
										<el-table-column label="姓名" prop="name">
										</el-table-column>
										<el-table-column label="电话" prop="tel">
										</el-table-column>
										<el-table-column label="时间" prop="created_at">
										</el-table-column>
									</el-table>
									<div class="user-event-admin-empty" v-else>
										<span></span>
										<div></div>
										<span class="a">还没有方案提交</span>
									</div>
									<div class="product_page" v-if="scheme.list">
										<el-pagination @current-change="schemeChange" background
											layout="prev, pager, next" :page-size="scheme.post.limit"
											:total="scheme.count">
										</el-pagination>
									</div>
								</div>
							</el-tab-pane>
						</el-tabs>
					</div>
				</transition>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "../company/Left.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left
		},
		data() {
			return {
				dataList: [{
					title: '全部',
					content: '123',
					status: 9,
				}, {
					title: '已发布',
					content: '123',
					status: 1,
				}, {
					title: '已过期',
					content: '999',
					status: 3
				}, {
					title: '审核中',
					content: '789',
					status: 0,
				}, {
					title: '未通过',
					content: '999',
					status: 2
				}],
				formInline: {
					user: '',
					region: ''
				},
				multipleSelection: [],
				currentPage1: 1,
				create_time: '',
				demand: {
					post: {
						page: 1,
						limit: 4,
						keywod: '',
						status: 9,
						type: "me"
					},
					list: [],
					count: 0,
				},
				tab_index: 0,
				show: true,
				show_scheme: false,
				activeName: 'item1',
				tabItem: [{
					label: '全部',
					name: 'item1',
					status: 9,
				}, {
					label: '其它',
					name: 'item2',
					status: 1,
				}],
				scheme: {
					post: {
						page: 1,
						limit: 10,
						demand_id: '',
					},
					list: [],
					count: 0,
				},
			}
		},
		mounted() {
			this.get_list()
		},
		computed: {
			...mapGetters(['user'])
		},
		methods: {
			toggleDel() {
				let that = this;
				this.multipleSelection.forEach(function(v) {
					that.handleDelete('', v.id)
				})
				this.get_list();
			},
			get_list() {
				this.$http_qm.post('demand/list', this.demand.post).then(res => {
					if (res.code == 200) {
						if (res.data == null) {
							this.demand.list = []
							this.demand.count = 0;
						} else {
							this.demand.list = res.data
							this.demand.count = res.count;
						}

					}
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(val) {
				this.demand.post.page = val;
				this.get_list();
			},
			toggleSelection() {
				// this.$refs.multipleTable.clearSelection();
				this.$nextTick(() => {
					this.$refs.multipleTable[this.tab_index].clearSelection()
				})
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleEdit(index, id) {
				this.$router.push({
					path: '/demand/add',
					query: {
						id: id
					}
				})
			},
			handleAedit(index, id) {
				console.log(index, id);
				this.update(id, 'reset');
			},
			update(id, update_type) {
				this.$http_qm.post('product/update', {
					id: id,
					update_type: update_type,
				}).then(res => {
					console.log(res);
				}).catch(err => {
					console.log(err);
				})
			},
			// 删除c产品
			handleDelete(index, id) {
				this.$http_qm.post('demand/delete', {
					id: id,
				}).then(res => {
					if (res.code == 200) {
						this.demand.list.splice(index, 1);
					}
				}).catch(err => {
					console.log(err);
				})
			},
			handleChech(id) {
				this.show = false
				this.scheme.post.demand_id = id
				this.scheme_list()
				document.querySelector('#app').scrollIntoView({
					behavior: 'auto'
				})
			},
			scheme_list() {
				this.$http_qm.post('scheme/list', this.scheme.post).then(res => {
					this.scheme.list = res.data
					this.scheme.count = res.count
				}).catch(err => {
					console.log(err)
				})
			},
			schemeChange(val) {
				this.scheme.post.page = val
				this.scheme_list()
			},
			needClick(tab) {
				console.log(tab.$attrs);
				// this.activity_post.status = tab.$attrs.status;
				// this.get_list();
			},
			onSubmit() {
				this.get_list()
			},
			handleClick(tab) {
				this.tab_index = tab.index
				this.demand.post.status = tab.$attrs.status;
				this.get_list();
			}
		}
	}
</script>

<!-- <style lang="less" src="../../assets/css/firm.less"></style> -->
<style lang="less">
	#admin {
		margin: 0;
		padding: 0;
		background: #e6e6e6;

		.wrapper {
			padding: 15px 0;
			width: 1200px;
			margin: 0 auto;
			flex-direction: row;
			justify-content: space-between;
			display: flex;

			.main_left {
				width: 200px;
				height: auto;
				text-align: center;
			}

			.transition-box {
				margin-bottom: 10px;
				width: 200px;
				height: 100px;
				border-radius: 4px;
				background-color: #409EFF;
				padding: 40px 20px;
				box-sizing: border-box;
			}

			.main_right {
				width: 980px;
				height: auto;
				min-height: 750px;
				background: #fff;
				border: 1px solid #e0e0e0;
				padding: 15px;

				.page {
					padding: 0;

					.page_nav {
						padding: 0 15px;
						display: flex;
						border-bottom: 3px #0088ff solid;

						.page_nav_tit {
							float: left;
							font-size: 18px;
							font-family: "微软雅黑";
							cursor: pointer;
							margin-right: 20px;
						}

						.page_nav_tit_desc {
							float: left;
							line-height: 16px;
							font-size: 12px;
							margin: 7px 0 0 0;
						}
					}

					.page_list {
						margin-top: 10px;

						.listbox {
							text-align: left;

							.el-form-item {
								margin-bottom: 0px;
							}

							td {
								border: none;
							}

							.product_title {
								font-size: 12px;
								width: 123px;
								height: 25px;
								color: #2a5db0;
								display: block;
								cursor: pointer;
							}

							.product_title:hover {
								color: #db4f33;
								text-decoration: underline;
							}

							.product_classes {
								font-size: 12px;
								display: block;

								b {
									color: #2a5db0;
									cursor: pointer;
								}

								b:hover {
									color: #db4f33;
									text-decoration: underline;
								}
							}

							.product_data {
								font-size: 12px;
								display: block;
							}

							.product_page {
								text-align: center;
								padding: 10px 0 30px;
							}
						}
					}
				}

				.schemePage {
					padding: 0;

					.demo-table-expand {
						font-size: 0;
					}

					label {
						width: 90px;
						color: #99a9bf;
					}

					.el-form-item {
						margin-right: 0;
						margin-bottom: 0;
						width: 50%;
					}

					td {
						border: none;
					}

					th {
						background: #f5f7fa;
					}

					.user-event-admin-empty {
						padding-top: 40px;
						text-align: center;
						height: 500px;
						position: relative;
						display: flex;
						flex-direction: column;

						div {
							height: 220px;
							background: url("https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/no.feebf58.png") no-repeat center;
							display: inline-block;
						}

						.a {
							font-size: 20px;
							vertical-align: middle;
							color: #0088ff;
						}
					}

					.product_page {
						text-align: center;
						padding: 10px 0 30px;
					}

					.el-form-item {
						width: 100%;
						display: flex;
						flex-direction: row;
					}

					.formdesc {
						.el-form-item__content {
							width: 758px;
							padding: 8px 0;
							line-height: 25px !important;
						}
					}
				}

			}

		}
	}
</style>
