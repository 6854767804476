<template>
	<div id="zc_verify">
		<div class="wrapper clearfix">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="content">
				<div class="page_nav clearfix">
					<div class="page_nav_tit">账号管理</div>
				</div>
				<el-tabs type="border-card">
					<el-tab-pane label="手机验证">
						<el-steps :active="active" simple>
							<el-step title="验证旧手机号"></el-step>
							<el-step title="验证新手机号"></el-step>
							<el-step title="完成验证"></el-step>
						</el-steps>
						<div class="c-form" v-show="active == 1">
							<form>
								<div class="c-phone clearfix">
									<span>当前手机号：</span>
									<input class="c-num" v-model="user.tel" :disabled="true" type="text" id="liu">
									<input class="c-send" type="button" @click="get_code(1)" value="发送验证码"
										:disabled="get_code_disabled">
									<!-- <div class="tips1"><i></i><em>该手机已与其他账号绑定，请更换</em></div> -->
								</div>
								<div class="c-test clearfix">
									<span>验证码：</span>
									<input type="text" v-model="verify.code" class="c-phone_num">
									<!-- <div class="tips2"><i></i><em>验证码错误</em></div> -->
								</div>
								<div class="c-sub">
									<input class="c-sub0" type="button" @click="verify_tel()" value="提交">
								</div>
							</form>
						</div>
						<div class="c-form" v-show="active == 2">
							<form>
								<div class="c-phone clearfix">
									<span>输入手机号：</span>
									<input class="c-num" v-model="add.tel" type="text" id="liu">
									<input class="c-send" @click="get_code(2)" type="button" value="发送验证码"
										:disabled="get_code_disabled">
									<!-- <div class="tips1"><i></i><em>该手机已与其他账号绑定，请更换</em></div> -->
								</div>
								<div class="c-test clearfix">
									<span>验证码：</span>
									<input type="text" v-model="add.code" class="c-phone_num">
									<!-- 	<div class="tips2"><i></i><em>验证码错误</em></div> -->
								</div>
								<div class="c-sub">
									<input class="c-sub0" type="button" @click="edit()" value="提交">
								</div>
							</form>
						</div>
						<div class="c-complete" v-show="active == 3">
							<div>
								<i></i>
								<span>您的手机：<em class="c-secret">{{add.tel}} </em>已完成验证。</span>
							</div>
							<p>
								<router-link to="/user_center/zc_message">返回会员中心首页</router-link>
								<em>|</em>
								<i class="g-next" @click="change">变更验证手机</i>
							</p>
						</div>
					</el-tab-pane>
					<el-tab-pane label="邮箱验证">
						<div class="f_hide f_jsp" style="display: block;">
							<div class="f_jsp_top" id="email_send_info">
								<p class="blue">完成邮箱验证，用于修改账号密码，并可及时收到商机邮件。</p>
								<div>
									<span>电子邮箱：</span>
									<input type="text" v-model="email" id="email" plasholder="正确输入邮箱地址">
									<a class="f_jsp_btn send_email_btn" @click="send_email()">发送验证邮件</a>
									<a href="javascript:void(0);"
										class="f_jsp_btn f_btn_hui email_btn">{{time_down}}秒后可重新发送</a>
								</div>
								<p id="email_msg">&nbsp;</p>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "./Left.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left
		},
		data() {
			return {
				active: 3,
				time_down: 60,
				get_code_disabled: false,
				add: {
					code: '',
					tel: '',
				},
				verify: {
					code: '',
					tel: '',
				},
				email: '',
			}
		},
		computed: {
			...mapGetters(['user'])
		},
		mounted() {


		},
		methods: {
			send_email() {
				this.$http_qm.post('user/send_email', {
					email: this.email
				}).then(res => {
					if (res.code != 200) {
						this.$message.info(res.msg)
					} else {
						setInterval(function() {
							this.time_down--;
							console.log(this.time_down);
						}, 1000)
					}
				}).catch(err => {
					console.log(err)
				})
			},
			verify_tel() {
				this.$http.post('user/verify_tel', {
					tel: this.user.tel,
					code: this.verify.code
				}).then(res => {
					if (res.code == 200) {
						this.active = 2;
						this.get_code_disabled = false
					} else {
						this.$message.info(res.msg)
					}

				}).catch(err => {
					console.log(err)
				})
			},
			edit() {
				this.$http.post('user/modify_tel', this.add).then(res => {
					if (res.code == 200) {
						this.active = 3;
					} else {
						this.$message.info(res.msg)
						this.get_code_disabled = false
					}

				}).catch(err => {
					console.log(err)
				})
			},
			get_code(type) {
				this.get_code_disabled = true
				let tel = '';
				var request_type = '';
				if (type == 1) {
					tel = this.user.tel
					request_type = 'verify_old_tel'
				} else {
					tel = this.add.tel
					request_type = 'verify_new_tel'
				}

				this.$http.post('user/get_code', {
					tel: tel,
					request_type: request_type
				}).then(res => {
					this.$message.info(res.msg)
					this.get_code_disabled = false
				}).catch(err => {
					console.log(err)
				})
			},
			change() {
				this.active = 1
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/expert.less"></style>
