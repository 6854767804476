<template>
	<div id="government_service">
		<div class="banner">
			<h1>
				政府服务
			</h1>
			<h2>
				助力区域产学研升级，服务地区企业创新发展
			</h2>
		</div>
		<div class="container">
			<div class="box1 box flex space-between">
				<img src="../../assets/img/service/government/img9.png">
				<div class="col2">
					<div class="title">技术大市场</div>
					<div class="content">
						线上+线下互通的服务平台，帮助政府运营技术市场服务体系。融合金融、孵化、需求、服务、供给、市场、政策、创新、人才、培育等全方位科创要素，从技术转移体系规划设计、线上平台建设与运营、线下服务体系建设与能力提升、供需资源挖掘集聚、要素资源导入与运营、活动策划举办、服务机构引培与管理、技术经理人培养等方面为区域技术市场建设运营提供全面的支撑与服务，促进和帮助企业持续实现内外部技术创新。
					</div>
					<div class="btn" @click="go_btn()">点击咨询</div>
				</div>
			</div>
			<div class="box2 box ">
				<div class="title">
					承办技术创新大赛
				</div>
				<div class="content">
					共同打造“互联网+产学研”新模式。将进一步发挥平台作用，推进区域技术交易市场建设，为区域科技创新活动提供支撑，
					<br /> 同时进一步完善高新科技创新体系，加快推动产业转型升级。
				</div>
				<div class="flex row1">
					<div class="li">
						<div class="text">
							招商引资推荐会
						</div>
					</div>
					<div class="li">

						<div class="text">
							科技招商培训班
						</div>
					</div>
					<div class="li">
						<div class="text">
							项目对接会
						</div>
					</div>
					<div class="li">
						<div class="text">
							举办成果路演会
						</div>
					</div>
					<div class="li">
						<div class="text">
							引进项目产业化落地
						</div>
					</div>
				</div>
				<div class="btn" @click="go_btn()">点击咨询</div>
			</div>

			<div class="box3 box flex space-between">
				<div class="col1">
					<div class="title">
						科创可视化数字平台
					</div>
					<div class="content">
						可视化数据平台是支撑可视化系统进行数据对接、分析建模和数据播放的专用产品。产品集成多数据源接入支持、多种回放模式支持等功能特性,

						为政府决策提供数据支撑。
						<br>
						基于大数据和互联网时代设计，它是一款自助式的大数据可视化工具，为您提供丰富的图标效果展示，帮助您洞察大数据的潜力和价值。
						支持多个报表在页面上灵活布局，自由组合，一目了然，快速响应用户需求。
					</div>

					<div class="btn" @click="go_btn()">点击咨询</div>
				</div>
				<img src="../../assets/img/service/government/img8.jpg">
			</div>
			<div class="box4 box flex space-between">
				<img src="../../assets/img/service/government/img10.png">
				<div class="col2">
					<div class="title">地方技术转移协作中心</div>
					<div class="content">
						科技牛技术转移协作中心的在于实行一站式管理和服务，为上海科技服务专家和技术经纪人积极服务当地科技创新提供了活动平台和空间；其次，通过协作中心的集中管道就可以联系到上海乃至长三角高校院所企业专家资源，不用各单位在当地再单独设站，解决了资源分散和对接效率不高的短板；第三，为各地政府部门开展技术转移和科技创新工作建立上海联络处提供了资源服务和基地保障，有效促进区域协同和创新发展。
					</div>
					<div class="btn fr" @click="go_btn()">点击咨询</div>
				</div>
			</div>

		</div>
		<div class="box5">
			<div class="container">
				<div class="row1">
					<div class="row2">

						<div class="text1">
							我想咨询
						</div>
						<div class="text2">
							留下您的联系方式，我们会第一时间与您联系
						</div>
						<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
						<el-input v-model.number="form.tel" placeholder="请输入您的联系方式"></el-input>
						<el-button plain @click="submit()">提交</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '科技牛—企业专业服务—— 提升企业创新力量与产品核心竞争力',
			meta: [{
					name: 'keywords',
					content: '企业创新，技术对接，技改支持，技术创新服务'
				},
				{
					name: 'description',
					content: '科技牛技术创新顾问为企业提供一站式对接服务，链接专家成果，助力企业创新。'
				}
			]
		},
		data() {
			return {
				form: {
					name: '',
					tel: ''
				}
			}
		},
		methods: {
			submit() {
				if (this.form.name == "") {
					this.$message.error('请输入您的姓名');
					return false
				}
				if (this.form.tel == "") {
					this.$message.error('请输入您的联系方式');
					return false
				}
				this.form.tel = this.form.tel.toString();
				this.$http_qm.post('form/add', {
					type: 7,
					info: this.form
				}).then(res => {
					if (res.code == 200) {
						this.form.tel = '';
						this.form.name = '';
						this.$message.success('提交成功');
					}else{
						this.$message.info(res.msg);
					}
				}).catch(err => {
					console.log(err)
				})
			},
			go_btn() {
				window.open('https://p.qiao.baidu.com/cps/chat?siteId=16956657&userId=32242741&siteToken=eadf366274507def6435288099c80348');
				/* document.querySelector('.box5').scrollIntoView({
					behavior: "smooth",
				}); */
			}
		}
	}
</script>

<style lang="less">
	@font-face {
		font-family: "Source-Han-Sans";
		src: url(../../assets/font/SourceHanSansCN-Normal.ttf);
	}

	#government_service {
		font-family: "Source-Han-Sans";
		width: 100%;
        min-width: 1400px;  
		.container {
			width: 1120px;
			margin: auto;
		}

		.box5 {
			height: 529px;
			margin-top: 57px;
			background: url(../../assets/img/service/government/img7.jpg) center center no-repeat;
			text-align: center;
			min-width: 1400px;

			.row1 {
				width: 50%;
			}

			.row2 {
				width: 480px;
				float: right;
				display: flex;
				flex-direction: column;
				margin: 76px 0px 0px 0px;
			}

			.text1 {
				font-size: 32px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 71px;
			}

			.text2 {
				font-size: 24px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #FFFFFF;
			}

			.form {
				width: 380px;
				margin: auto;

			}

			button {
				margin: 37px auto 0px;
				width: 160px;
			}

			.el-input {
				margin: 43px auto 0px;
				width: 380px;
			}

			input {}
		}

		.box4 {
			img {
				margin-right: 32px;
			}

			.col2 {
				padding-top: 67px;
			}
		}

		.box3 {
			img {
				margin-left: 32px;
			}
		}

		.box2 {

			.title,
			.content {
				text-align: center;
			}

			.row1 {
				margin: 48px 0px 0px 0px;

				.li {

					width: 224px;
					height: 400px;
					font-size: 24px;
					font-family: Source-Han-Sans;
					font-weight: bold;
					color: #FFFFFF;
					line-height: 30px;

					&::before {
						content: "";
						color: #FFFFFF;
						width: 4px;
						height: 30px;
						background: #FFFFFF;
						float: left;
						margin: 42px 0px 0px 23px;
					}

					.text {
						margin: 42px 0px 0px 40px;
						width: 20px;
					}

					&:nth-child(1) {
						background: url(../../assets/img/service/government/img6.jpg);
					}

					&:nth-child(2) {
						background: url(../../assets/img/service/government/img5.jpg);
					}

					&:nth-child(3) {
						background: url(../../assets/img/service/government/img4.jpg);
					}

					&:nth-child(4) {
						background: url(../../assets/img/service/government/img3.jpg);
					}

					&:nth-child(5) {
						background: url(../../assets/img/service/government/img2.jpg);
					}
				}
			}

			.btn {
				margin: auto;
			}
		}

		.box1 {
			margin-top: 40px;

			img {
				margin-right: 58px;
			}

			.btn {
				float: right;
			}

			.col2 {
				margin-top: 40px;
			}
		}

		.box {
			margin-top: 77px;

			& .title {
				font-size: 32px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #333333;
				line-height: 71px;
			}

			& .content {
				font-size: 16px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #333333;
				line-height: 30px;
			}

			.btn {
				cursor: pointer;
				width: 240px;
				height: 52px;
				line-height: 52px;
				background: #6299FF;
				box-shadow: -2px 5px 14px 2px rgba(59, 137, 255, 0.22);
				border-radius: 26px;
				font-size: 20px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #FFFFFF;
				text-align: center;
				margin-top: 44px;
			}
		}

		.banner {
			height: 900px;
			background: url(../../assets/img/service/government/img1.jpg) center center no-repeat;
			text-align: center;
			min-width: 1400px;

			h1 {
				font-size: 52px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #FFFFFF;
				line-height: 71px;
				padding-top: 280px;
			}

			h2 {
				margin-top: 62px;
				font-size: 42px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #FFFFFF;
				line-height: 71px;
			}
		}
	}
</style>
