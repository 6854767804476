import Vue from 'vue'
import App from './App.vue'

import router from './router/index.js'
router.afterEach(() => {
	window.scrollTo(0, 0)
})

import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
	ak: 'E7agLuf201GGfoMSlKbm7KnIDCRqrt7q'
})
import animated from "animate.css"
Vue.use(animated)
// 分享
import Share from 'vue-social-share'
import 'vue-social-share/dist/client.css';
import '../node_modules/social-share.js/dist/js/social-share.min.js'
Vue.use(Share)

import {
	http,
	httpqm,
	baseUrl,
	baseUrlqm
} from './api/index'

Vue.prototype.$http_qm = httpqm
Vue.prototype.$http = http
Vue.prototype.baseUrl = baseUrl
Vue.prototype.baseUrlqm = baseUrlqm

Vue.config.productionTip = false

import MetaInfo from 'vue-meta-info';
Vue.use(MetaInfo);
import config from '@/config'
var ws = new WebSocket(process.env.NODE_ENV === 'development' ? config.wsUrl2.dev:config.wsUrl2.pro);

import {
	Notification
} from 'element-ui';
ws.onopen = function(evt) {
	console.log("Connection open ..." + JSON.stringify(evt));
};

ws.onmessage = function(evt) {
	var data = JSON.parse(evt.data);
	Notification.info({
		title: data.title,
		message: data.message,
		duration: 6000,
	});

};

ws.onclose = function(evt) {
	console.log("Connection closed." + JSON.stringify(evt));
};
ws.onerror = function(evt) {
	console.log('Error occured: ' + evt.data);
};
Vue.prototype.ws = ws


new Vue({
	router,
	store,
	render: h => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'))
	}
}).$mount('#app')
