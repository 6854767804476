<template>
	<div id="list">
		<Top></Top>
		<div class="slot-wrapper">
			<div class="product-container">
				<div class="page-tab-wrap">
					<!---->
				</div>
				<div class="container">
					<div class="filter-wrap">
						<div class="s-bread-wrap">
							<span class="bread-all">
								<el-breadcrumb separator-class="el-icon-arrow-right">
									<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
									<el-breadcrumb-item>产品列表</el-breadcrumb-item>
								</el-breadcrumb>
							</span>
							<span class="bread-counter">共
								<em>{{product.count}}</em>件相关商品
							</span>
						</div>
						<div class="search-listbox search-margin">
							<div class="sslist-ylxs" v-for="(v,k) in classify" :key="k">
								<strong>{{v.title}}：</strong>
								<div :class="v.active ? 'active' : 'unfold'" class="sslist-ssnr">
									<el-radio-group v-model="radio[k]" size="small" @change="tagChange">
										<el-radio-button :label="s" :value="s" v-for="(s,y) in v.tags" :key="y"></el-radio-button>
									</el-radio-group>
								</div>
								<span v-if="k == 0 || k == 1" :class="v.active ? 'item-down' :'item-span'" v-html="v.active ? '收起' : '展开'"
								 @click="v.active = !v.active"></span>
							</div>
						</div>
					</div>
					<div class="content">
						<div class="content-list">
							<div class="card" v-for="(item,index) in product.list" :key="index" @click="more(item.id,item.company_id)">
								<div class="card-layout">
									<div class="link">
										<div class="card-img">
											<div class="lazyload">
												<el-image style="width: 226px; height: 226px" v-if="Array.isArray(item.cover)" :src="item.cover[0]" fit="cover"></el-image>
												<el-image style="width: 226px; height: 226px" v-else :src="item.cover" fit="cover"></el-image>
											</div>
											<div class="show-desc desc">查看详情</div>
										</div>
										<div class="flags-wrap">
											<div class="act-icon-wrap"></div>
										</div>
										<div class="card-desc">
											<div class="card-price">
												<div v-if="item.price==0">
													面议
												</div>
												<div v-else>
													<i>¥</i>{{item.price}}万
												</div>
											</div>
											<!-- <div class="card-sales">≥1台</div> -->
											<div class="card-name">
												<span class="card-name-title">
													{{item.title}}
												</span>
											</div>
											<div class="card-tags">

												<span class="card-tag card-tag-tag" v-for="(tag,tagk) in item.tag" :key="tagk">{{tag}}</span>
											</div>
											<div class="card-bottom">
												<span class="card-bottom-shop">
													<span class="name">
														<span v-if="item.company_info" @click="href({path:'/store/index',query:{id:item.company_id}})">{{item.company_info.name}}</span>
													</span>
													<span class="icons">
														<span class="sd-icon-wrap" style="color: rgb(102, 102, 102);">
															<img class="sd-icon" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/product_ico_1.png">
														</span>
														<em class="qi-icon-wrap" style="color: rgb(102, 102, 102);">
															<img class="qi-icon" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/product_ico_2.png">
															<span class="qi-duration" v-if="item.company_info&&item.company_info.star_year">{{item.company_info.star_year}}年</span>
															<span class="qi-duration" v-else>0年</span>
														</em>
													</span>
												</span>
												<div class="card-bottom-address">
													<i class="el-icon-location-outline"></i>{{item.company_info.province}}{{item.company_info.city}}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="page-content">
						<el-pagination :page-size="product.post.limit" @current-change="handleCurrentChange" background layout="prev, pager, next,jumper"
						 :total="product.count" :current-page="currentPage">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import citylist from '../../assets/js/city.min.js'
	import Top from "@/components/top.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
		},
		data() {
			return {
				radio: ['不限', '不限'],
				classify: [{
						title: '所在地',
						active: false,
						tags: ['不限']
					},
					{
						title: '产品类型',
						active: false,
						tags: ['不限']
					}
				],
				product: {
					list: [],
					count: 0,
					post: {
						limit: 20,
						page: 1,
						province: '',
						one_cate: '',
						status:"1",
					}
				},
				currentPage:1
			}
		},
		mounted() {
			var listcity = [];
			citylist.forEach(function(item) {
				listcity.push(item.p)
				return;
			});
			this.classify[0].tags = this.classify[0].tags.concat(listcity);
			if (typeof(this.$route.params.product_name) != 'undefined') {
			
				// this.product.post.province = this.$route.params.product_name
			}
			
			this.get_cate();
			this.get_list()
		},
		created() {
			var query = this.$route.query
			if(typeof(query.page) != 'undefined'){
				this.product.post.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			}else{
				this.product.post.page =1
				this.currentPage =1
			}
		},
		watch:{
			$route(){
				let query = this.$route.query
				if(typeof(query.page) != 'undefined'){
					this.product.post.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				}else{
					this.product.post.page =1
					this.currentPage =1
				}
				this.get_list();
			},
		},
		methods: {
			href(url){
				this.$router.push(url)
			},
			get_list() {
				this.$http_qm.post('product/list', this.product.post).then(res => {

					this.product.list = res.data;
					this.product.count = res.count;
				}).catch(err => {
					console.log(err)
				})
			},
			tagChange() {
				console.log(this.radio)
				let post = this.product.post
				this.radio.forEach(function(val, k) {
					if (k == 0) {
						post.province = val == '不限' ? '' : val
					} else {
						post.one_cate = val == '不限' ? '' : val
					}
				})
				this.product.post.page = 1;
				this.get_list();
			},

			get_cate() {
				let that = this;
				that.$http_qm.post('product_cate/list', {}).then(res => {
					let cate_arr = res.data;
					cate_arr.forEach(function(item) {
						that.classify[1].tags.push(item.name);
						/* item.children.forEach(function(tag) {
							
						}) */
					})
				}).catch(err => {
					console.log(err)
				})
			},
			handleCurrentChange(page) {
				this.$router.push({
					path: '/product',
					query: {
						page: page
					}
				})
				// this.product.post.page = val;
				// this.get_list()
				document.querySelector('#app').scrollIntoView({
					behavior: "auto"
				});
			},
			more(id,company_id) {
				let routeData = this.$router.resolve({
					// path: '/store/anli_detail',
					name:'store_anli_detail',
					query: {
						id: id,
						company_id:company_id
					}
				})
				window.open(routeData.href,'_blank')
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/product.less"></style>
