<template>
	<div id="firmEnter">
		<el-drawer :modal="false" :wrapperClosable="false" :title="title" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
			<div class="firmEnterbox">
				<div class="footFixedForm_box">
					<div class="footFixedForm_l">企业入驻<span>Online Registration</span></div>
					<input v-model="fromData.name" type="text" placeholder="您的姓名*" class="footFixedForm_input">
					<input v-model="fromData.tel" type="text" placeholder="您的手机号*" class="footFixedForm_input">
					<input v-model="fromData.firmName" type="text" placeholder="公司名称*" class="footFixedForm_input">
					<input @click="submit" type="button" value="立即登记" class="footFixedForm_btn">
					<div class="footFixedForm_error"></div>
					<div class="footFixedForm_r">
						<div class="footFixedForm_r_t">
							<i class="el-icon-phone-outline"></i>入驻咨询热线
						</div>
						<div class="footFixedForm_r_b">021-60496259</div>
					</div>
				</div>
			</div>
		</el-drawer>
		<!-- ttb/btt/rtl/ltr 上下左右 -->
	</div>
</template>

<script>
	export default {
		props: {
			drawer: null,
			direction: {
				type: String,
			},
			title: {
				type: String
			}
		},
		data() {
			return {
				fromData:{
					name:'',
					tel:'',
					firmName:''
				}
			}
		},
		methods: {
			handleClose(done) {
				done();
			},
			submit(){
				let fromData = this.fromData;
				if(fromData.name == '' && fromData.tel == '' && fromData.firmName == ''){
					this.$message('请填写正确内容');
				}else{
					this.$message('提交成功');
				}
			}
		}
	}
</script>

<style lang="less">
	#firmEnter {
		padding: 0;
		margin: 0;
		.el-drawer{
			height: 145px !important;
			background: rgba(0,0,0,0.8);
			.el-drawer__header{
				margin: 0;
				padding: 0 ;
				width: 1200px;
				margin: 0 auto;
			}
		}
		.firmEnterbox {
		    width: 1200px;
		    margin: 0 auto;
		    position: relative;
		    max-height: 99999999px;
			.footFixedForm_box {
			    height: 115px;
			    padding-left: 75px;
			    background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/ruzhu.png) left center no-repeat;
			    overflow: hidden;
				.footFixedForm_l {
				    float: left;
				    width: 135px;
				    margin-top: 36px;
				    font-size: 26px;
				    color: #f30;
					span {
					    display: block;
					    margin-top: 10px;
					    font-size: 13px;
					    color: #f4f4f4;
					}
				}
				.footFixedForm_input {
				    display: block;
				    float: left;
				    width: 150px;
				    padding: 0 20px;
				    height: 40px;
				    line-height: 40px;
				    margin: 38px 0 0 16px;
				    border: 0;
				    border-radius: 55px;
				    background: #fff;
				    font-size: 14px;
				    color: #666;
				}
				.footFixedForm_btn {
				    display: block;
				    float: left;
				    width: 100px;
				    height: 40px;
				    line-height: 40px;
				    margin: 38px 0 0 16px;
				    background: #f30;
				    color: #fff;
				    border: 0;
				    border-radius: 55px;
				    font-size: 14px;
				    cursor: pointer;
				}
				.footFixedForm_r {
				    float: right;
				    margin-top: 26px;
					.footFixedForm_r_t {
					    font-size: 14px;
					    color: #f4f4f4;
						i{
							font-size: 18px;
						}
					}
					.footFixedForm_r_b {
						margin-top: 10px;
					    font-size: 30px;
					    color: #f30;
					}
				}
			}
		}
	}
</style>
