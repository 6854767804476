<template>
	<div id="achievemenAdd">
		<Top></Top>
		<div class="grzx-bgcont">
			<div class="container">
				<div class="grzx_left">
					<Left></Left>
				</div>
				<div class="grzx-right fr">
					<div class="user-box">
						<div class="user-title">
							<i></i>
							<span>我发布的技术成果</span>
						</div>
						<el-tabs v-model="activeName" @tab-click="handleClick">
							<el-tab-pane :label="v.label" v-for="(v,k) in tabs" :key="k" :name="v.name">
								<el-table :data="v.list" style="width: 100%" v-if="v.list.length>0">
									<el-table-column label="成果图" prop="img" align="center">
										<template slot-scope="scope">
											<el-image style="width: 134px; height: 84px" :src="Array.isArray(scope.row.img)?scope.row.img[0]:scope.row.img" fit="cover"></el-image>
										</template>
									</el-table-column>
									<el-table-column label="技术名称" prop="title" align="center">
									</el-table-column>
									<el-table-column label="行业" prop="industry" align="center">
									</el-table-column>
									<el-table-column label="合作方式" prop="cooperation_type_mes" align="center">
									</el-table-column>
									<el-table-column label="成熟度" prop="maturity" align="center">
									</el-table-column>
									<el-table-column label="操作" align="center">
										<template slot-scope="scope">
											<el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
											<el-button size="mini" type="danger" @click="handleDelete(scope.$index,scope.row.id)">删除</el-button>
										</template>
									</el-table-column>
								</el-table>
								<div class="no-publish" v-else>
									<p>您还没有发布过技术成果哦，快去发布技术成果吧~</p>
									<router-link to="/achivement/add" class="btn-box">
										<div class="btn btn-rOrange">免费发布</div>
									</router-link>
								</div>
								<div class="page">
									<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
									 :page-size="v.limit" layout="prev, pager, next, jumper" :total="v.count">
									</el-pagination>
								</div>
							</el-tab-pane>

						</el-tabs>

					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Top from './top.vue';
	import Left from "../company/Left.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			Left
		},
		data() {
			return {
				activeName: 'first',
				tabs: [{
						label: '全部',
						name: 'first',
						list: [],
						page: 1,
						status: 9,
						limit: 5,
						count: 0,
					}, {
						label: '待审核',
						name: 'two',
						list: [],
						page: 1,
						status: 0,
						limit: 5,
						count: 0,
					},
					{
						label: '已发布',
						name: 'three',
						list: [],
						page: 1,
						status: 1,
						limit: 5,
						count: 0,
					}, {
						label: '审核未通过',
						name: 'four',
						list: [],
						page: 1,
						status: 2,
						limit: 5,
						count: 0,
					}
				],
				currentPage: 1,
				TabCur: 0,
				company_id:''
			}
		},
		mounted() {

			/* this.tabs.forEach(function(v) {

			}) */
			this.get_list(this.TabCur);
		},
		methods: {
			get_list(index) {
				let list = this.tabs[index];
				this.$http.post('achievement/list', {
					page: list.page,
					limit: 5,
					status: list.status,
					type: 'me',
					company_id:this.company_id
				}).then(res => {
					list.list = res.data;
					list.count = res.count;
					this.$forceUpdate();
				}).catch(err => {
					console.log(err)
				})
			},
			handleClick(tab) {
				this.TabCur = tab.index;
				this.get_list(this.TabCur);
			},
			handleEdit(id) {
				this.$router.push({'path':'/achivement/add',query:{id:id}})
			},
			handleDelete(index, id) {
				console.log(index, id);

				this.$http.post('achievement/del', {
					id: id
				}).then(res => {
					this.tabs[this.TabCur].list.splice(index, 1);
					this.$message.info(res.msg)
				}).catch(err => {
                  console.log(err);
				})
			},
			// 页码
			handleSizeChange(val) {
				console.log(`每页 ${val} 条`);
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				let list = this.tabs[this.TabCur];
				list.page = val;
				this.get_list(this.TabCur);
			}
		}
	}
</script>

<style lang="less">
	#achievemenAdd {
		color: #666;
		background-color: #fff;

		.grzx-bgcont {
			height: auto;
			overflow: hidden;
			background-color: #f5f5f5;

			.container {
				margin: 0 auto;
				width: 1200px;
				margin-top: 26px;
				margin-bottom: 20px;
				overflow: hidden;

				.grzx_left {
					width: 160px;
					height: auto;
					float: left;
					overflow: hidden;
				}

				.grzx-right {
					width: 1020px;

					.user-box {
						padding: 20px;
						background-color: #fff;
						margin-bottom: 20px;
						min-height: 492px;

						.user-title {
							margin-bottom: 10px;
							line-height: 16px;

							i {
								display: inline-block;
								width: 4px;
								height: 16px;
								background: #00a3e7;
								margin-right: 10px;
								vertical-align: top;
							}

							span {
								font-size: 16px;
								color: #333;
								font-weight: 700;
							}
						}

						td {
							border: none;
						}

						.no-publish {
							padding: 30px 20px 30px 430px;
							background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/no-publish.png) no-repeat 260px center #fff;

							p {
								font-family: 'Microsoft Yahei', '\5b8b\4f53', sans-serif, tahoma, arial, 'Hiragino Sans GB';
								font-size: 14px;
								line-height: 1.42857143;
								color: #666;
							}

							.btn-box {
								margin-top: 15px;

								.btn-rOrange {
									display: inline-block;
									vertical-align: middle;
									touch-action: manipulation;
									cursor: pointer;
									text-align: center;
									white-space: nowrap;
									border-radius: 20px;
									border: 1px solid #ff7720;
									background-color: #ffffff;
									color: #ff7720;
									font-size: 12px;
									padding: 4px 65px;
								}
							}
						}
					}
				}

				.fr {
					float: right;
				}
			}
		}
	}
</style>
