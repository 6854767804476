<template>
	<div id="case_index">
		<Top :search_text="param.title" page_type="case"></Top>
		<div class="wrap backgrey">
			<div class="column-search">
				<!-- 面包屑导航-->
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>案例列表</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="container">
				<div class="cate">
					<h3>分类：</h3>
					<div class="li" v-for="(v,k) in cate" :key="k" @click="tap_nav(k)" :class="{'active':k==tab_index}">
						{{v.name}}
					</div>
				</div>
				<div class="list">
					<div class="li" v-for="(v,k) in list" @click="href('/case/detail',{id:v.id})"
						:key="k">
						<el-image class="cover" :src="v.img[0]" fit="cover" lazy></el-image>
						<div class="title">
							{{v.app_title}}
							<span class="iconfont icon-re" v-if="v.is_top"></span>
						</div>
						<div class="tag flex">
							<div class="tag_li flex" v-for="(v2,k2) in v.tag" :key="k2">{{v2}}
								
							</div>
						</div>
						<div class="flex num">
							<div class="cate_name">{{v.cate_name}}</div>
							<div class="col2">
								<span> 点赞 {{v.support_num}} </span>
								<span> 浏览 {{v.view_num}} </span>
							</div>
						</div>

					</div>
				</div>
				<div class="page">
					<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
						:page-size="param.limit" layout="total, prev, pager, next, jumper" :total="count">
					</el-pagination>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
	import Top from "@/components/top.vue"
	import flexRight from "@/components/flexRight.vue"
	export default {
		components: {
			Top,flexRight
		},
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		data() {
			return {
				tab_index: 0,
				currentPage: 1,
				param: {
					limit: 12,
					page: 1,
					status: 1,
					cate_id: '',
					keyword: '',
				},
				list: [],
				count: 0,
				cate: [],
				industrial: []
			}
		},
		watch: {
			$route() {
				let query = this.$route.query
				console.log('route', query)
				if (typeof(query.tab_index) != 'undefined') {
					this.tab_index = query.tab_index;
					this.param.cate_id = this.cate[this.tab_index].id;
				}
				if (typeof(query.page) != 'undefined') {
					this.param.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				} else {
					this.param.page = 1;
					this.currentPage = 1;
				}
				if (typeof(query.keyword) != 'undefined' && query.keyword != '') {
					this.param.title = query.keyword;
				}else{
					this.param.title = '';
				}
				this.get_list();
			},
		},
		created() {
			let query = this.$route.query
			console.log('created', query)
			if (typeof(query.page) != 'undefined') {
				this.param.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			} else {
				this.param.page = 1;
				this.currentPage = 1;
			}
			if (typeof(query.keyword) != 'undefined') {
				this.param.title = query.keyword;
			}
		},
		mounted() {
			let query = this.$route.query
			console.log('mounted', query)
			if (typeof(query.demand_type) != 'undefined') {
				this.param.demand_type = query.demand_type
			}
			this.get_cate();
		},
		methods: {
			tap_nav(k) {
				this.$router.push({
					path: '/case',
					query: {
						page: 1,
						tab_index: k,
					}
				})
			},
			handleCurrentChange(page) {
				this.$router.push({
					path: '/case',
					query: {
						page: page
					}
				})
			},
			async get_list() {
				await this.$http_qm.post('case/list', this.param).then(res => {
					this.list = res.data;
					this.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},

			get_cate() {
				this.$http_qm.post('industry_cate/list', {
					type: 2,
					status:1,
				}).then(res => {
					this.cate = res.data;
					this.cate.unshift({
						id: '',
						name: '全部'
					})
					this.get_list();
				}).catch(err => {
					console.log(err)
				})
			},
			changeList(cate_index, index, name) {
				if (cate_index == 1) {
					if (name == '不限') {
						this.type_index = 0;
						this.param.demand_type = '';
					} else {
						this.type_index = index;
						this.param.demand_type = name
					}
				} else if (cate_index == 2) {
					if (name == '不限') {
						this.industry_index = 0;
						this.param.industry = ''
					} else {
						this.industry_index = index;
						this.param.industry = name
					}

				} else {
					if (name == '不限') {
						this.province_index = 0;
						this.param.province = ''
					} else {
						this.province_index = index;
						this.param.province = name
					}

				}
				this.param.page = 1;
				this.get_list();

			},

			href(path, query) {
				this.$router.push({
					path: path,
					query: query
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	#case_index {
		.backgrey {
			width: 1220px;
			margin: 23px auto 0px;
		}

		.page {
			text-align: center;
			margin: 48px auto;
		}

		.container {
			width: 1220px;
			margin: auto;
		}

		.cate {
			margin: 21px auto;
			background: #F8F9F9;
			align-items: center;
			border: 1px solid #F1F1F1;
			display: flex;
			padding: 19px 0px 19px 53px;

			.li {
				padding: 6px 16px;
				margin: 0px 10px;
				cursor: pointer;
				font-size: 12px;
 
				&.active {
					background: #106AFF;
					color: #FFFFFF
				}
			
			}
		}

		.list {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-row-gap: 33px;
			grid-column-gap: 30px;

			&>.li {
				width: 280px;
				border: 1px solid #F1F1F1;
				transition: border 1s;
				cursor: pointer;

				.cover {
					width: 280px;
					height: 210px;
				}

				&:hover .title {
					color: #106AFF;
				}

				&:hover {
					border: 1px solid #106AFF;
				}

				.title {
					transition: color 1s;
					height: 40px;
					font-size: 18px;
					font-weight: bold;
					margin: 10px 11px 14px;
					color: #333333;
					line-height: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					word-break: break-all;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					.icon-re{
						color: red; font-size: 20px;
					}
				}

				.tag {
					overflow: hidden;
					flex-wrap: wrap;
					height: 27px;
					padding: 0px 11px;
					color: #666666;

					.tag_li {
						padding: 0px 6px 0px;
						margin:0px 12px 5px 0px;
						border: 1px solid #666666;
						border-radius: 10px;
						line-height: 24px;
					}
				}

				.num {
					justify-content: space-between;
					margin: 10px 11px 10px;
					padding: 6px 0px 0px;
					color: #666666;
					border-top: 1px dashed #F1F1F1;

					span {
						margin-left: 20px;
					}
				}
			}
		}

	}
</style>
