<template>
	<div id="shoreList">
		<Top :search_text="company_search.keyword" page_type="company"></Top>
		<div class="content">
			<div class="content_grid">
				<div class="main_wrap">
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
						<el-breadcrumb-item>企业列表</el-breadcrumb-item>
					</el-breadcrumb>
					<div class="filter-category-wrap">
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">认证状态：</label>
								<div :class="isActive ? 'active' : 'unfold'">
									<ul class="box">
										<li v-for="(c, index) in authentication_arr" :key="index"
											:class="{ checked: index == authentication_index }"
											@click="authentication_select(index, c.val)">
											{{ c.name }}
										</li>
									</ul>
								</div>

							</div>
						</div>
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">所在地区：</label>
								<div :class="isActive ? 'active' : 'unfold'">
									<ul class="box">
										<li v-for="(c, index) in city.sf" :key="index"
											:class="{ checked: index == province_index }"
											@click="changeList(index, c.name)">
											{{ c.name }}
										</li>
									</ul>
								</div>
								<span class="item-span" v-html="isActive ? '收起' : '展开'"
									@click="isActive = !isActive"></span>
							</div>
						</div>
						<div class="cate-item ">
							<div class="cate-item-inner">
								<label class="item-label">产业领域：</label>
								<div>
									<ul class="box">
										<li v-for="(c, index) in industry_cate" :key="index"
											v-bind:class="[index == industry_index || c.name == company_search.industry ? 'checked' : '']"
											@click="industryClick(index, c)">
											{{ c }}
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div class="witkey-list">
						<!-- 企业列表 -->
						<div class="item-wrap" v-for="(v, index) in company.list" :key="index">
							<div class="s1"></div>
							<div class="witkey-item">
								<div class="witkey-item-left">
									<div class="witkey-item-top">
										<div class="member-tag" v-if="v.is_certification == 1" title="企业会员"></div>
										<div class="witkey-item-top_header">
											<div class="witkey-avatar">
												<el-avatar v-if="v.has_logo == true" :src="v.logo" :size="50">
												</el-avatar>
												<el-avatar v-else :size="50" v-html="v.logo"></el-avatar>
											</div>
											<div class="witkey-name_tag">
												<section class="witkey-title">
													<router-link target="_blank"
														:to="{ name: 'shop', query: { id: v.id } }">
														<h4 class="witkey-name text-overflow">{{ v.name }}</h4>
													</router-link>
													<span class="city-icon ico">
														<img
															src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/location.png" />
														<span>{{ v.province }}</span>
													</span>
												</section>
												<section class="witkey-tags">
													<el-tag size="mini" effect="dark" v-if="v.is_certification == 1">
														认证企业</el-tag>
													<el-tag size="mini" effect="dark" v-else>未认证企业</el-tag>
													<!-- <el-tag size="mini" type="info" effect="dark">保</el-tag>
													<el-tag size="mini" type="warning" effect="dark">漫游</el-tag>
													<el-tag size="mini" type="danger" effect="dark">严选</el-tag>
													<el-tag size="mini" type="success" effect="dark">5年老店</el-tag> -->
												</section>
											</div>
										</div>
										<div class="witkey-item-top_content">
											<div class="witkey-info">
												<div class="witkey-medial">
													<div class="witkey-info">
														<div class="witkey-dync">
															<span>
																联系人：
																<i>{{ v.contact_name }}</i>
															</span>
															<b></b>
															<span>
																职务：
																<i>{{ v.contact_post }}</i>
															</span>
															<b></b>
															<span>
																联系电话：
																<i>{{ v.contact_tel }}</i>
															</span>
														</div>
													</div>
												</div>
												<div class="expert-tree">
													<div class="expert-tree-inner">
														<span class="title">擅长：</span>
														<span class="specialty-wrap">
															<span v-for="(tag, tag_index) in v.tag"
																:key="tag_index">{{ tag }}</span>
														</span>
													</div>
												</div>
												<!-- <div class="employer-review">整体来说，服务很不错，网站技术也给力，基本能做到自己想要看到的网站，给大家推荐！网站刚刚建成，希望售后能和前期一样，继续以客户为中心，及时高效的帮助客户解决使用中遇到的困难！</div> -->
											</div>
										</div>
										<div class="witkey-item-top_footer">
											<div style="color: #0088ff;" @click="access(v.template,v.id)">
												<el-button size="small">进店看看</el-button>
											</div>
											<div @click="consult(v.template,v.id)">
												<el-button size="small" class="zixun">免费咨询</el-button>
											</div>
										</div>
									</div>
								</div>
								<div class="witkey-item-right">
									<el-tabs v-if="v.product != null && v.product.length > 0" v-model="activeName"
										@tab-click="handleClick(index)">
										<el-tab-pane label="店铺产品" name="first"
											v-if="v.product != null && v.product.length > 0">
											<div class="list-warp" v-for="(p2, index) in v.product" :key="index">
												<div class="list" @click="product_href(p2.id, v.id,v.template)"
													v-if="index < 2">
													<div class="item-img" v-if="p2.cover != null">
														<el-image style="width: 241px; height: 140px;"
															v-if="Array.isArray(p2.cover) && p2.cover.length > 0"
															:src="p2.cover[0]" class="img" fit="cover" align="">
														</el-image>
														<el-image style="width: 241px; height: 140px;" v-else
															:src="p2.cover" class="img" align="" fit="cover"></el-image>
													</div>
													<div class="list-bottom">
														<div class="item-link">
															<div class="item-link-title">
																<span class="desc">{{ p2.title }}</span>
															</div>
															<span class="jzos">热度：{{ p2.view_num }}</span>
														</div>
														<div class="item-info" v-if="p2.tag&&p2.tag.length>0">
															<template v-for="(v,k) in p2.tag">
																<el-tag size="small" effect="plain" v-if="k<2" :key="k">
																	{{v}}
																</el-tag>
															</template>

														</div>
													</div>
												</div>
											</div>
										</el-tab-pane>
										<!-- <el-tab-pane label="店铺服务" name="second">
											<div class="services-list-warp list-warp">
												<div class="services-list">
													<div class="item-link">
														<div class="item-img">
															<img class="lazy" src="//rms.zhubajie.com/resource/redirect?key=homesite/task/3800.jpg/origine/c86c74c0-231a-4377-9695-c925a78632f0&s.w=160&s.h=160">
														</div>
														<div class="info">
															<div class="item-title">
																<span class="desc">企业网站，网站定制开发，网站二次开发，商城建设</span>
															</div>
															<div class="serv-labels">
																<span class="price">$66</span>
																<span class="sale-count">成交量：66</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</el-tab-pane> -->
									</el-tabs>
									<div v-else class="shop-des">
										<div class="witkey-item-des">
											<p class="witkey-item-des_title">店铺简介</p>
											{{ v.introduction }}
										</div>
										<p class="earnest-money">店铺已经缴纳诚信保证金10000元保障雇主权益，出问题保证金赔付</p>
									</div>
								</div>
							</div>

							<div class="s2"></div>
						</div>
						<!-- 标签页 -->
						<div class="pagination">
							<el-pagination background @current-change="handleCurrentChange" :current-page="currentPage"
								:page-size="pageSize" layout="prev, pager, next" :total="company.count"></el-pagination>
						</div>
					</div>
				</div>
				<!-- 猜您想看 -->
				<div class="recommend-wrap">
					<div class="kc-bottom-content">
						<div class="kc-bottom-title"><span class="fl">猜您想看</span></div>
						<ul class="kc-bottom-list">
							<template v-for="(v, index) in company.list">
								<li class="kc-bottom-item shop" v-if="index < 4" :key="index" @click="shop(v.id)">
									<div class="item-box">
										<p class="kc-bottom-server-name-wrap">
											<span class="kc-bottom-server-name">{{ v.name }}</span>
											<i class="icon-arrow-right"></i>
											<span class="fc-location fr">
												<i class="icon-location-self"></i>
												<span>{{ v.province }}</span>
											</span>
										</p>
										<div class="kc-bottom-server-data-grid">
											<span class="kc-tips">擅长：</span>
											<p class="goodat">
												<span v-for="(tag, tag_index) in v.tag"
													:key="tag_index">{{ tag }}</span>
											</p>
										</div>
										<div class="kc-bottom-server-info">
											<span class="kc-bottom-server-cover">
												<el-image style="width: 50px; height: 50px" :src="v.logo" fit="cover">
												</el-image>
											</span>
											<div class="kc-bottom-server-info-bottom" v-if="v.product != null">
												<div class="kc-bottom-service-title">{{ v.product[0].title }}</div>
												<div class="kc-bottom-server-info-bottom-data">
													<span class="fl kc-bottom-price">￥ {{ v.product[0].price }}/起</span>
												</div>
											</div>
										</div>
									</div>
								</li>
							</template>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
	import Top from '@/components/top.vue';
	import flexRight from "@/components/flexRight.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,
			flexRight
		},
		data() {
			return {
				province_index: 0,
				activeName: 'first',
				currentPage: 1, //初始页
				pageSize: 10, //每页个数
				isActive: false,
				city: [],
				authentication_index: 0,
				company_search: {
					province: '',
					keyword: '',
					page: 1,
					limit: 10,
					status: 9,
					industry: '',
					is_certification: '9',
				},
				company: {
					list: [],
					reload: false,
					city: '',
					count: 0
				},
				industry_cate: [],
				industry_index: 0,
				authentication_arr: [{
						name: '不限',
						val: '9'
					},
					{
						name: '认证企业',
						val: '1'
					},
					{
						name: '非认证企业',
						val: '0'
					}
				]
			};
		},
		mounted() {
			let query = this.$route.query;
			if (typeof query.keyword != 'undefined') {
				this.company_search.keyword = this.$route.query.keyword;
			}
			if (typeof this.$route.params.industry != 'undefined') {
				this.company_search.industry = this.$route.params.industry;
			}

			this.get_industry_cate();
			this.get_list();
			this.get_city();
		},
		watch: {
			$route() {
				let query = this.$route.query;
				if (typeof query.page != 'undefined') {
					this.company_search.page = parseInt(query.page);
					this.currentPage = parseInt(query.page);
				} else {
					this.company_search.page = 1;
					this.currentPage = 1;
				}
				if (typeof query.keyword != 'undefined') {
					this.company_search.keyword = query.keyword;
					this.currentPage = 1;
					this.company_search.page = 1;
				}
				this.get_list();
			}
		},
		created() {
			let query = this.$route.query;
			if (typeof query.page != 'undefined') {
				this.company_search.page = parseInt(query.page);
				this.currentPage = parseInt(query.page);
			} else {
				this.company_search.page = 1;
				this.currentPage = 1;
			}
		},
		methods: {
			authentication_select(index, val) {
				this.authentication_index = index;
				this.company_search.is_certification = val;
				this.get_list();
			},
			industryClick(index, name) {
				if (name == '不限') {
					this.company_search.industry = '';
				} else {
					this.company_search.industry = name;
				}
				this.industry_index = index;

				this.get_list();
			},
			get_industry_cate() {
				this.$http_qm
					.post('expert/cate', {
						type: 'industrial'
					})
					.then(res => {
						this.industry_cate = res.data.industrial;
						this.industry_cate.unshift('不限');
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_city() {
				await this.$http_qm
					.post('city/list', {
						type: 'search_province'
					})
					.then(res => {
						this.city = res.data;
						this.city.sf.unshift({
							name: '不限'
						});
					})
					.catch(err => {
						console.log(err);
					});
			},
			async get_list() {
				let search_data = this.company_search;
				await this.$http_qm
					.post('company/list', search_data)
					.then(res => {
						if (res.data != null) {
							this.company.list = res.data;
							this.company.count = res.count;
						} else {
							this.company.list = [];
							this.company.count = 0;
						}

						this.company.reload = false;

						this.$forceUpdate();
					})
					.catch(err => {
						console.log(err);
					});
			},
			// 公司店铺切换
			handleClick(tab, event) {
				console.log(tab, event);
			},
			// 前往页码
			handleCurrentChange(page) {
				this.$router.push({
					path: '/store',
					query: {
						page: page
					}
				});
				// this.company_search.page = val
				// this.get_list();
				// document.querySelector('#app').scrollIntoView({
				// 	behavior: "auto"
				// });
			},
			change(e) {
				console.log(e);
			},
			shop(id) {
				let routeData = this.$router.resolve({
					name: 'shop',
					query: {
						id: id
					}
				});
				window.open(routeData.href, '_blank');
			},
			changeList(index, name) {
				console.log(index);
				if (name == '不限') {
					this.company_search.province = '';
				} else {
					this.company_search.province = name;
				}
				this.province_index = index;
				this.company_search.page = 1;
				this.get_list();
				document.querySelector('#shoreList').scrollIntoView({
					behavior: 'smooth'
				});
			},
			access(type, id) {
				switch (type) {
					case 0:
						var routeUrl = this.$router.resolve({
							path: '/store/index',
							query: {
								id: id
							}
						})
						window.open(routeUrl.href, '_blank')
						break;
					case 2:
						var routeUrl1 = this.$router.resolve({
							path: '/c/vip01/index',
							query: {
								id: id
							}
						})
						window.open(routeUrl1.href, '_blank')
						break;
				}
			},
			consult(type, id) {
				switch (type) {
					case 0:
						var routeUrl = this.$router.resolve({
							path: '/store/leave',
							query: {
								id: id
							}
						})
						window.open(routeUrl.href, '_blank')
						break;
					case 2:
						var routeUrl1 = this.$router.resolve({
							path: '/c/vip01/contact',
							query: {
								id: id
							}
						})
						window.open(routeUrl1.href, '_blank')
						break;
				}
			},
			product_href(id, company_id, type) {
				switch (type) {
					case 0:
						var routeUrl = this.$router.resolve({
							name: 'store_anli_detail',
							query: {
								company_id: company_id,
								id: id
							}
						});
						window.open(routeUrl.href, '_blank')
						break;
					case 2:
						var routeUrl1 = this.$router.resolve({
							path: '/c/vip01/product_detail',
							query: {
								productID: id,
								id: company_id
							}
						})
						window.open(routeUrl1.href, '_blank')
						break;
				}
			},
		}
	};
</script>

<style lang="less">
	#shoreList {
		font: 14px/1.5 tahoma, arial, 'Hiragino Sans GB', 'Microsoft Yahei', '\5b8b\4f53', sans-serif;
		color: #333;


		.content {
			background: #eee;
			min-width: 1200px;
			height: 100%;
			padding-bottom: 40px;
		}

		.content_grid {
			margin: 0 auto;
			width: 1200px;
		}

		.main_wrap {
			padding-bottom: 30px;
		}

		.el-breadcrumb {
			height: 60px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			clear: both;
			zoom: 1;
		}

		.filter-category-wrap {
			font-size: 12px;
			background: #fff;
			margin-bottom: 14px;
			position: relative;
			height: 100%;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
		}

		.cate-item {
			margin-top: 10px;
			position: relative;
			overflow: hidden;
			padding: 0 85px 0 85px;
		}

		.cate-item .cate-item-inner {
			overflow: hidden;
		}

		.cate-item .cate-item-inner .item-label {
			position: absolute;
			top: 5px;
			left: 20px;
			font-size: 14px;
			font-weight: 700;
			vertical-align: middle;
		}

		.cate-item .cate-item-inner .item-span {
			position: absolute;
			top: 5px;
			right: 20px;
			padding-right: 10px;
			cursor: pointer;
			transition: max-height 0.25s;
		}

		.cate-item .cate-item-inner .item-span::after {
			content: '';
			display: inline-block;
			width: 12px;
			height: 8px;
			margin-left: 2px;
			background: url('https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/arrow_icon.png') no-repeat -83px -18px;
		}

		.unfold {
			height: 40px !important;
			transition: max-height 0.25s;
		}

		.active {
			height: auto !important;
			transition: max-height 0.25s;
		}

		.cate-item ul {
			padding: 0;
			list-style: none;
			line-height: 20px;
		}

		.cate-item li {
			padding: 4px 12px;
			display: inline-block;
			border-radius: 8px;
			text-align: center;
			cursor: pointer;
			transition: all 0.3s linear;
			margin: 0 0 8px 15px;
		}

		.cate-item li:hover {
			color: #0088ff;
		}

		.cate-item li.checked {
			background: #f0f0f0;
			color: #0088ff;
			padding: 4px 12px;
			border-radius: 12px;
		}

		/* 企业列表 */
		.witkey-list {
			z-index: 9;
			background-color: #fff;
		}

		.witkey-list .item-wrap {
			border: 1px solid transparent;
			transition: box-shadow 0.35s;
			border-top-color: #eee;
			cursor: pointer;
			position: relative;
		}

		.witkey-list .item-wrap:hover {
			box-shadow: 5px 0px 5px -5px #0088ff, -5px 0px 5px -5px #0088ff;
		}

		.witkey-list .item-wrap .s1 {
			background-color: #0088ff;
			width: 0;
			height: 1px;
			top: -20px;
			box-shadow: 0px 0px 0px #0088ff;
			right: 0;
			border-radius: 20px 10px 10px 20px;
			-webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.97);
			-moz-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.97);
			transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.97);
		}

		.witkey-list .item-wrap .s2 {
			background-color: #0088ff;
			width: 0;
			height: 1px;
			bottom: -35px;
			box-shadow: 0px 0px 0px #0088ff;
			left: 0;
			border-radius: 10px 20px 20px 10px;
			z-index: 1000;
			-webkit-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.97);
			-moz-transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.97);
			transition: all 0.3s cubic-bezier(0.17, 0.67, 0.46, 0.97);
		}

		.witkey-list .item-wrap:hover .s1 {
			width: 100%;
			top: -20px;
		}

		.witkey-list .item-wrap:hover .s2 {
			width: 100%;
			bottom: -35px;
		}

		.witkey-list .witkey-item {
			box-sizing: border-box;
			height: 285px;
			padding: 12px 0 18px 17px;
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			-ms-flex-pack: justify;
			justify-content: space-between;
		}

		.witkey-list .witkey-item-left {
			margin-top: 30px;
			width: 560px;
			height: 100%;
		}

		.witkey-list .witkey-item-right {
			width: 560px;
			height: 100%;
			padding-right: 40px;
			padding-left: 15px;
		}

		.witkey-list .witkey-item .witkey-item-top .member-tag {
			position: absolute;
			left: 17px;
			top: 66px;
			width: 14px;
			height: 14px;
			background: url(//as.zbjimg.com/static/nodejs-zbj-search-api/widget/localize-witkey/img/member_5ab0f5d.png) no-repeat center;
			background-size: auto;
			background-size: 100%;
			z-index: 5;
		}

		.witkey-list .witkey-item .witkey-item-top_header {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-pack: justify;
			justify-content: space-between;
			margin-bottom: 20px;
		}

		.witkey-list .witkey-item .witkey-item-top_content {
			margin-bottom: 22px;
		}

		.witkey-list .witkey-item .witkey-item-top_footer {
			display: -ms-flexbox;
			display: flex;
			flex-direction: row;
			-ms-flex-align: center;
			align-items: center;
			position: relative;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-avatar {
			width: 50px;
			height: 50px;
			position: relative;
			cursor: pointer;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-avatar img {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 50%;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag {
			-ms-flex: 1;
			flex: 1;
			padding-left: 19px;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .witkey-title {
			display: flex;
			align-items: center;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .witkey-title .witkey-name {
			max-width: 336px;
		}

		.text-overflow {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .city-icon {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			color: #333;
			padding-left: 18px;
			height: 21px;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .ico {
			line-height: 17px;
			font-size: 12px;
			white-space: nowrap;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .city-icon img {
			width: 10px;
			height: 13px;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .city-icon span {
			margin-left: 7px;
			max-width: 100px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .witkey-tags {
			display: flex;
			margin-top: 10px;
		}

		.witkey-list .witkey-item .witkey-item-top_header .witkey-name_tag .el-tag {
			margin-right: 5px;
		}

		.witkey-list .witkey-info .witkey-medial {
			margin-bottom: 9px;
		}

		.witkey-list .witkey-info .expert-tree {
			width: 510px;
			height: 27px;
			line-height: 27px;
			margin-bottom: 9px;
			position: relative;
		}

		.witkey-list .witkey-info .employer-review {
			color: #999;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.witkey-list .witkey-info {
			margin-top: 20px;
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-direction: column;
			font-size: 12px;
			cursor: pointer;
		}

		.witkey-list .witkey-info .expert-tree .expert-tree-inner {
			display: inline-block;
			padding-left: 11px;
			border-radius: 2px;
			background-color: #f9f9f9;
			white-space: nowrap;
			max-width: 510px;
			height: 27px;
			overflow: hidden;
		}

		.witkey-list .witkey-info .expert-tree .title {
			font-size: 12px;
			color: #333;
		}

		.witkey-list .witkey-info .expert-tree .specialty-wrap {
			color: #999;
			white-space: normal;
		}

		.witkey-list .witkey-info .expert-tree .specialty-wrap span {
			display: inline-block;
			margin-right: 9px;
		}

		.witkey-list .witkey-info .witkey-dync span {
			color: #999;
		}

		.witkey-list .witkey-info .witkey-dync span i {
			color: #0088ff;
			font-style: normal;
		}

		.witkey-list .witkey-info .witkey-dync b {
			margin: 0 26px 0 22px;
			border-left: 1px solid #e7e7e7;
		}

		.witkey-item-top_footer .el-button:nth-child(1) {
			color: #0088ff;
			border: 1px solid #0088ff;
		}

		.witkey-item-top_footer .el-button:nth-child(1):hover a {
			color: #ffffff;
		}

		.witkey-item-top_footer .zixun {
			color: #ffffff !important;
			background-color: #0088ff;
			margin-left: 15px;
		}

		.witkey-item-top_footer .zixun a {
			color: #ffffff;
		}

		/* 卡片右边 */
		.witkey-item-right .el-tabs__nav {
			background: #ffffff !important;
		}

		.witkey-item-right .el-tabs__active-bar {
			background-color: #0088ff;
		}

		.witkey-item-right .el-tabs__item {
			height: 30px;
			line-height: 30px;
		}

		.witkey-item-right .el-tabs__item.is-active {
			color: #0088ff;
		}

		.witkey-item-right .el-tabs__header {
			margin: 0 0 10px;
		}

		.witkey-item-right .list-warp {
			padding: 0;
			display: inline-block;
		}

		.witkey-item-right .list {
			width: 243px;
			height: 216px;
			box-sizing: border-box;
			border: 1px solid #eee;
			position: relative;
			cursor: pointer;
			transition: all 0.35s;
		}

		.witkey-item-right .list-warp:nth-child(1) {
			margin-right: 15px;
		}

		.witkey-item-right .list-warp:nth-child(3) {
			margin-left: 15px;
		}

		.witkey-list .list:hover {
			background-color: #fffaf6;
			border-color: #ffd2b3;
		}

		.witkey-item-right .item-img {
			width: 241px;
			height: 140px;
			background-color: #eee;
		}

		.witkey-item-right .item-img img {
			width: 100%;
			height: 100%;
		}

		.witkey-item-right .list-bottom {
			width: 100%;
			height: 70px;
			padding: 15px 10px;
			box-sizing: border-box;
			position: relative;
		}

		.witkey-item-right .list-bottom .item-link {
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
			font-size: 12px;
			margin: 0px;
			line-height: 12px;
		}

		.witkey-item-right .item-link .item-link-title {
			width: 140px;
			display: flex;
			align-items: center;
		}

		.witkey-item-right .item-link .item-link-title .desc {
			max-width: 655px;
			padding: 0;
			font-size: 12px;
			color: #333;
			height: 14px;
			line-height: 14px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			text-align: left;
		}

		.witkey-item-right .item-link .item-link-title .desc:hover {
			color: #0088ff;
		}

		.witkey-item-right .item-link .jzos {
			flex: 1;
			padding-left: 10px;
			font-weight: 400;
			color: #bbb;
			font-size: 12px;
			text-align: right;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.list-bottom .item-info {
			font-size: 12px;
			color: #bbb;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
             margin-top: 5px;
			.el-tag {
				margin: 0px 10px 0px 0px;
			}
		}

		.witkey-item-right .services-list-warp {
			display: flex;
			justify-content: flex-start;
			display: inline-block;
		}

		.witkey-item-right .services-list-warp .services-list {
			width: 158px;
			height: 220px;
			box-sizing: border-box;
			border: 1px solid #eee;
			position: relative;
			cursor: pointer;
			transition: all 0.35s;
		}

		.services-list-warp .services-list:hover {
			background-color: #fffaf6;
			border-color: #ffd2b3;
		}

		.witkey-item-right .item-link {
			box-sizing: border-box;
			font-size: 12px;
		}

		.witkey-item-right .item-link .item-img {
			width: 156px;
			height: 156px;
			background-color: #eee;
		}

		.witkey-item-right .item-img .lazy {
			width: 100%;
			height: 100%;
		}

		.witkey-item-right .item-link .info {
			width: 100%;
			height: 60px;
			padding: 8px;
			box-sizing: border-box;
			display: flex;
			justify-content: space-between;
			align-items: stretch;
			flex-direction: column;
			font-size: 12px;
		}

		.item-link .info .item-title .desc {
			font-size: 12px;
			max-width: 139px;
			line-height: 15px;
			color: #333;
			overflow: hidden;
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			word-wrap: break-word;
		}

		.witkey-item-right .item-link .info .item-title {
			width: 100%;
			height: 60px;
		}

		.witkey-item-right .item-link .info .serv-labels {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.item-link .info .serv-labels .price {
			font-weight: 600;
			font-size: 12px;
			color: #ff6900;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.item-link .info .serv-labels .sale-count {
			font-size: 12px;
			font-weight: 400;
			color: #bbb;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.witkey-item-right .shop-des {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
			font-size: 12px;
			padding-right: 40px;
		}

		.witkey-list .witkey-item-right .shop-des .witkey-item-des {
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 6;
			-webkit-box-orient: vertical;
			word-wrap: break-word;
		}

		.witkey-list .witkey-item-right .shop-des .witkey-item-des_title {
			height: 60px;
			line-height: 60px;
			font-weight: 700;
			color: #666;
		}

		.witkey-list .witkey-item-right .shop-des .earnest-money {
			padding-left: 20px;
			margin-bottom: 28px;
			background: url(//as.zbjimg.com/static/nodejs-zbj-search-api/widget/localize-witkey/img/earnest-money_c870311.png) no-repeat left center;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		/* 标签页 */
		.pagination {
			text-align: center;
			padding: 30px 0;
			font-size: 12px;
		}

		.pagination .el-input {
			width: 50px !important;
		}

		/* 才您想看 */
		.recommend-wrap {
			margin-bottom: 0px;
		}

		.kc-bottom-content {
			background-color: #fff;
			padding-top: 8px;
			display: flex;
			flex-direction: column;
		}

		.kc-bottom-content .kc-bottom-title {
			font-size: 18px;
			padding: 0 20px;
			line-height: 40px;
			color: #333;
			font-weight: 700;
			border-color: transparent;
		}

		.kc-bottom-content .kc-bottom-list {
			list-style: none;
			padding: 0 20px;
			display: grid;
			grid-template-columns: repeat(4,1fr);
		}

		.kc-bottom-block-wrap .kc-bottom-item:first-child {
			margin-left: 20px;
		}

		.kc-bottom-block-wrap .kc-bottom-item:not(:last-child) {
			margin-right: 30px;
		}

		.kc-bottom-item {
			width: 265px;
			border: 1px solid #e7e7e7;
			margin-bottom: 18px;
			display: inline-block;
			vertical-align: top;
			box-sizing: border-box;
			position: relative;
			font-size: 12px;
			line-height: 1.2;
			padding-bottom: 22px;
			transition: box-shadow 0.35s;
			cursor: pointer;
		}

		.kc-bottom-item .item-box {
			padding: 20px 20px 0;
			display: flex;
			flex-direction: column;
		}

		.kc-bottom-server-name-wrap {
			margin: 0;
			padding: 0;
			clear: both;
		}

		.kc-bottom-server-name-wrap .kc-bottom-server-name {
			float: left;
			max-width: 150px;
			font-size: 14px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		.kc-bottom-server-name-wrap .icon-arrow-right {
			float: left;
			margin-left: 5px;
			margin-top: 4px;
			width: 6px;
			height: 8px;
			vertical-align: middle;
			background: url('https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/arrow-right.png') 50% no-repeat;
			display: inline-block;
		}

		.kc-bottom-server-name-wrap .fc-location {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 47%;
			display: flex;
			align-items: center;
		}

		.fr {
			float: right;
		}

		.fl {
			float: left;
		}

		.kc-bottom-server-name-wrap .fc-location .icon-location-self {
			margin-right: 7px;
			width: 10px;
			height: 13px;
			vertical-align: middle;
			background-size: 100%;
			display: inline-block;
		}

		.kc-bottom-server-name-wrap .fc-location span {
			display: inline-block;
			max-width: 36px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			color: #999;
		}

		.kc-bottom-server-data-grid {
			height: 20px;
			line-height: 20px;
			background: #f9f9f9;
			display: inline-block;
			max-width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			vertical-align: middle;
			font-size: 0;
			margin-top: 12px;
		}

		.kc-bottom-server-data-grid .kc-tips {
			border-left: 2px solid #ddd;
			color: #666;
			padding-left: 5px;
			font-size: 12px;
		}

		.kc-bottom-server-data-grid .goodat {
			color: #999;
			display: inline-block;
			vertical-align: top;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			font-size: 12px;
			max-width: 155px;
		}

		.kc-bottom-server-data-grid .goodat span {
			margin-right: 10px;
		}

		.kc-bottom-server-info {
			padding: 12px 0 10px;
		}

		.kc-bottom-server-info .kc-bottom-server-cover {
			display: block;
			width: 50px;
			height: 50px;
			float: left;
		}

		.kc-bottom-server-info .kc-bottom-server-cover img {
			width: 100%;
			height: 100%;
		}

		.kc-bottom-server-info .kc-bottom-server-info-bottom {
			margin-left: 80px;
		}

		.kc-bottom-server-info .kc-bottom-server-info-bottom .kc-bottom-service-title {
			font-weight: 400;
			font-size: 12px;
			height: 30px;
			line-height: 15px;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			word-wrap: break-word;
		}

		.kc-bottom-server-info-bottom-data .kc-bottom-price {
			font-weight: 700;
			color: #0088ff;
		}
	}
</style>
