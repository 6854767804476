<template>
	<div id="flexRight">
		<ul v-if="up == false" :class="flex ? 'fixright-fix' : 'fixright'" style="top: 264px;">
			<a target="_blank" href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09">
				<li class="zxkf">
					<a>客服</a>
				</li>
			</a>
			<el-popover placement="left" width="150" trigger="hover">
				<div class="right-show">
					<img width="150" height="150" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/server.jpg">
				</div>
				<li class="wx" slot="reference">
					<span>微信</span>
				</li>
			</el-popover>
			<el-popover placement="left" width="150" trigger="hover">
				<div class="right-show">
					<img width="150" height="150" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index1.jpg">
				</div>
				<li class="app" slot="reference">
					<span>APP</span>
				</li>
			</el-popover>
			<el-popover placement="left" width="150" trigger="hover">
				<div class="right-show">
					<span style="font-size: 20px;color: #0088ff;font-weight: 600;">021-60496259</span>
				</div>
				<li class="lxwm" slot="reference">
					<span>电话</span>
				</li>
			</el-popover>
			<li class="top" @click="backTop">
				<span>顶部</span>
			</li>
			<li class="up" @click="up = true">
				<span>收起</span>
			</li>
		</ul>
		<div v-if="up == true" class="fixright-s" @click="up = false">
			<span></span>
			<i class="el-icon-arrow-down"></i>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				flex: false,
				up:false
			}
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll)
		},
		methods: {
			handleScroll() {
				let scrollY = document.documentElement.scrollTop
				if (scrollY > 600) {
					this.flex = true;
				} else {
					this.flex = false;
				}
			},
			backTop(){
				document.querySelector('#app').scrollIntoView({
					behavior: "auto"
				});	
			}
		},
	}
</script>

<style lang="less">
	#flexRight {
		.fixright {
			position: absolute;
			right: 20px;
			z-index: 5;
			list-style-type: none;
		}

		.fixright-fix {
			position: fixed;
			right: 20px;
			top: 50% !important;
			margin-top: -164px;
			z-index: 5;
		}

		li {
			position: relative;
			width: 58px;
			height: 64px;
			padding-top: 40px;
			cursor: pointer;
			border: 1px solid #e4e4e4;
			border-top: 0;
			background-position: center 12px;
			background-repeat: no-repeat;
			box-sizing: border-box;
			background: #fff url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/icon_r.png) no-repeat;

			&:hover {
				background-color: #0088ff;
				border-color: #0088ff;
				background-image: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/icon_rh.png);
				span{
					color:#fff;
				}
				a{
					color:#fff;
				}
			}
			
		}

		.zxkf {
			background-position: 11px 6px;
		}

		.wx {
			background-position: 11px -122px;
		}

		.app {
			background-position: 11px -186px;
		}

		.lxwm {
			background-position: 11px -245px;
		}

		.top {
			background-position: 11px -370px;
		}

		.up {
			padding-top: 0;
			height: 26px;
			line-height: 26px;
			background-position: 23px -435px;
			background-color: #f1f7fe;
			box-sizing: border-box;
		}

		a {
			display: block;
			text-align: center;
			font-size: 12px;
			color: #474747;
		}

		span {
			display: block;
			text-align: center;
			font-size: 12px;
			color: #474747;
		}

		.fixright-s {
		    position: fixed;
		    top: 40%;
		    height: 64px;
		    background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/bg_kf_s.png) no-repeat;
		    z-index: 5;
		    overflow: hidden;
		    transition: all .3s;
		    cursor: pointer;
			right: 0;
			width: 63px;
			span {
			    position: absolute;
			    top: 9px;
			    left: 9px;
			    width: 46px;
			    height: 46px;
			    background: url(https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/bg_kf_s.png) center no-repeat;
			}
			i {
			    position: absolute;
			    right: 8px;
			    bottom: 4px;
				font-size:12px;
				color: #fff;
			}
		}
	}
</style>
