<template>
	<div id="add">
		<div class="create-event-container">
			<el-page-header @back="goBack" content="活动发布"></el-page-header>
			<div class="base-info">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
					<div class="base-info-block">
						<h1 class="base-info__title">基本信息</h1>
						<div class="base-info__content">
							<el-form-item label="活动名称" prop="title">
								<el-input v-model="ruleForm.title" placeholder="不少于5个字,最多50个字"></el-input>
							</el-form-item>
							<el-form-item label="活动时间" required>
								<el-col :span="11">
									<el-form-item prop="start">
										<!-- <el-date-picker placeholder="选择日期" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker> -->
										<el-date-picker v-model="ruleForm.activity_time.start" type="date" placeholder="选择日期时间"
										 style="width: 100%;">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col class="line" :span="2">-</el-col>
								<el-col :span="11">
									<el-form-item prop="end">
										<el-date-picker v-model="ruleForm.activity_time.end" type="date" placeholder="选择日期时间"
										 style="width: 100%;">
										</el-date-picker>
									</el-form-item>
								</el-col>
							</el-form-item>
							<el-form-item label="活动地址" prop="city">
								<el-col :span="6">
									<el-select @change="province_change" v-model="province_index" placeholder="选择省">
										<el-option v-for="(v,k) in city" :key="k" :label="v.p" :value="k"></el-option>
									</el-select>
								</el-col>
								<el-col :span="6">
									<el-select v-model="ruleForm.city" placeholder="选择市">
										<template v-if="city[province_index]">
											<el-option v-for="(v,k) in city[province_index].c" :key="k" :label="v.n" :value="v.n"></el-option>
										</template>
									</el-select>
								</el-col>
								<el-col :span="12">
									<el-input v-model="ruleForm.addr" placeholder="点击输入详细地址"></el-input>
								</el-col>
							</el-form-item>
							<el-form-item label="活动海报" prop="cover">
								<el-col :span="10">
									<el-upload class="avatar-uploader" :action="baseUrl+'file/upload_one'" :show-file-list="false" :on-success="handleAvatarSuccess"
									 :data="{dir:'activity'}" :before-upload="beforeAvatarUpload">
										<img v-if="ruleForm.cover.length>0" :src="ruleForm.cover[0]" class="avatar">
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
									</el-upload>
								</el-col>
								<el-col :span="14">
									<div class="create-box-poster-tips">
										<p class="create-box-poster-tips__title">温馨提示：</p>
										<p>1、图片尺寸 1080*640，.jpg 或 .png格式，不超过 4M</p>
										<p>2、精美海报有助于增加报名量，并有机会获得强力推荐！</p>
									</div>
								</el-col>
							</el-form-item>
							<!-- <el-form-item label="活动类型" prop="type">
								<el-popover placement="bottom-start" width="900" trigger="click">
								  <div v-for="(v,k) in 3" :key="k" style="margin-top:10px;display: flex;flex-direction: row;align-items: flex-start;">
									  <div style="margin-right: 10px; width: 80px;">行业:</div>
								      <div>
										  <el-radio v-model="ruleForm.type" v-for="(v,i) in 12" :key="i" :label="i" border size="medium" style="margin: 0 30px 15px 0;">备选项1</el-radio>
									  </div>
								    </div>
								  <div class="create-box-content" slot="reference" @click="Body = !Body">
									  <span class="multiselect__single">{{ruleForm.type}}</span>
									  <i :class="Body? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
								  </div>
								</el-popover>
							</el-form-item> -->
							<el-form-item label="活动标签" prop="tags">
								<el-tag :key="tag" v-for="tag in ruleForm.tag" closable :disable-transitions="false" @close="handleClose(tag)">
									{{tag}}
								</el-tag>
								<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" ref="saveTagInput" size="small"
								 @keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
								</el-input>
								<el-button plain v-else class="button-new-tag" size="small" @click="showInput">点击添加标签</el-button>
							</el-form-item>

						</div>
					</div>

					<div class="base-info-block">
						<h1 class="base-info__title">活动详情</h1>
						<div class="base-info__content">
							<vue-editor v-model="ruleForm.content" @focus="onEditorFocus" use-custom-image-handler
					@image-added="handleImageAdded" @blur="onEditorBlur" @selection-change="onSelectionChange" />
						</div>
					</div>
					<div class="base-info-submit">
						<div>
							<el-button>存草稿</el-button>
							<el-button :disabled="!read_agreement" type="primary" @click="submitForm('ruleForm')">发布</el-button>
						</div>
						<label class="create-checkbox">
							<input type="checkbox" v-model="read_agreement" name="service" class="create-checkbox__input">
							<span>已阅读并同意
								<span class="create-text" @click="protocol">《科技牛服务协议》</span>
							</span>
						</label>
					</div>
				</el-form>
			</div>
		
		</div>
	</div>
</template>

<script>
	import city from '../../assets/js/city.min.js';
	import {
		VueEditor
	} from "vue2-editor";
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			VueEditor
		},
		data() {
			return {
				inputVisible: false,
				inputValue: '',
				read_agreement: false,
				province_index: '',
				ruleForm: {
					title: '',
					activity_time: {
						start: '',
						end: ''
					},
					province: '',
					city: '',
					addr: '',
					cover: [],
					tag: [],
					content: '',
				},
				rules: {
					title: [{
						required: true,
						message: '请输入活动名称'
					}],
					time: [{
						type: 'date',
						required: true,
						message: '请选择日期'
					}],
					addr: [{
						type: 'date',
						required: true,
						message: '请选择活动区域'
					}],
					city: [{
						required: true,
						message: '请选择活动区域'
					}],
					cover: [{
						required: true,
						message: '请上传一张海报'
					}],

					tag: [{
						required: true,
						message: '请添加活动标签'
					}]
				},
				id: '',
				is_add: false,
				city:city
			}
		},
		mounted() {
			if (typeof(this.$route.query.id) != 'undefined') {
				this.id = this.$route.query.id;
				this.get_detail()
			}
			this.company_detail();
		},
		methods: {
			company_detail(){
				this.$http_qm
					.post('company/detail', {
						type: 'me'
					}).then(res => {
						if (res.code == 200) {
							this.ruleForm.company_id = res.data.id;
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			handleImageAdded(file, Editor, cursorLocation, resetUploader) {
				console.log(file);
				var formData = new FormData();
				formData.append('file', file);
				formData.append('dir', 'activity');
				this.$http
					.post('file/upload_one', formData,{headers:{"Content-Type":"multipart/form-data;"}})
					.then(res => {
						console.log(res);
						let url = res.data.img_url; // Get url from response
						Editor.insertEmbed(cursorLocation, 'image', url);
						resetUploader();
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			get_detail() {
				this.$http_qm.post('activity/detail', {
					id: this.id
				}).then(res => {
					// this.ruleForm = res.data;
					let that = this;
					this.city.forEach(function(v, k) {
						if (v.p == res.data.province) {
							that.province_index = k;
						}
					})
					this.ruleForm.activity_time.start = res.data.time.start * 1000
					this.ruleForm.activity_time.end = res.data.time.end * 1000
					this.ruleForm.title = res.data.title
					this.ruleForm.province = res.data.province
					this.ruleForm.city = res.data.city
					this.ruleForm.addr = res.data.addr
					this.ruleForm.cover = res.data.cover
					this.ruleForm.tag = res.data.tag
					this.ruleForm.content = res.data.content
				}).catch(err => {
					console.log(err)
				})
			},
			province_change() {
				console.log(this.province_index);
				this.ruleForm.province = this.city[this.province_index].p;
				this.ruleForm.city = '';
			},
			// 表单
			submitForm(formName) {
				if (!this.read_agreement) {
					this.$message.info('请先同意协议');
					return false;
				}
				this.$refs[formName].validate((valid) => {
					if (!valid) {
						return false;
					}
					
					if (!this.is_add) {
						this.ruleForm.activity_time.start = this.ruleForm.activity_time.start / 1000
						this.ruleForm.activity_time.end = this.ruleForm.activity_time.end / 1000
					}
					if (this.id == '') {
						var url = 'activity/add'
					} else {
						url = 'activity/update'
						this.ruleForm.id = this.id;
					}
					let data = this.ruleForm;
					this.$http_qm.post(url, data).then(res => {
						this.is_add = true
						this.$message.info(res.msg);
						if(res.code == 200){
							this.$router.go(-1)
						}
					}).catch(err => {
						console.log(err);
					})

				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 编辑器
			onEditorBlur(quill) {
				console.log("editor blur!", quill);
			},
			onEditorFocus(quill) {
				console.log("editor focus!", quill);
			},
			onSelectionChange(range) {
				console.log("selection change!", range);
			},
			//   上传封面
			handleAvatarSuccess(res) {
				if(res.code ==200){
					this.ruleForm.cover.push(res.data.img_url);
				}else{
					this.$message.error(res.msg);
				}
				
			},
			beforeAvatarUpload(file) {
				const isIMG = file.type === 'image/jpeg' || 'image/jpg' || 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isIMG) {
					this.$message.error('上传头像图片只能是 JPG/PNG/JPEG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isIMG && isLt2M;
			},
			// 标签添加
			handleClose(tag) {
				this.ruleForm.tag.splice(this.ruleForm.tag.indexOf(tag), 1);
			},

			showInput() {
				this.inputVisible = true;
				this.$nextTick(() => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},

			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					this.ruleForm.tag.push(inputValue);
				}
				this.inputVisible = false;
				this.inputValue = '';
			},
			// ...
			protocol() {
				this.$router.push({
					path: '/activity/protocol'
				})
			},
			goBack() {
				this.$router.go(-1);
			}
		}
	}
</script>

<style lang="less">
	#add {
		.create-event-container {
			width: 1080px;
			margin: 20px auto 0;

			.base-info {
				box-sizing: border-box;
				margin-bottom: 40px;

				.base-info-block {
					display: block;
					border-radius: 12px;
					background: #fff;
					margin-top: 20px;
					overflow: hidden;

					.base-info__title {
						line-height: 60px;
						padding-left: 30px;
						border-bottom: 1px solid #0088ff;
						font-size: 18px;
						color: #0088ff;
						font-weight: bold;
						margin-right: -30px;
					}

					.base-info__content {
						padding: 40px;

						.create-box-content {
							display: flex;
							flex-direction: row;
							align-items: center;
							width: 930px;
							position: relative;
							background-color: #FFF;
							background-image: none;
							border-radius: 4px;
							border: 1px solid #DCDFE6;
							box-sizing: border-box;
							color: #606266;
							justify-content: space-between;
							font-size: inherit;
							height: 40px;
							line-height: 40px;
							padding: 0 15px;
						}

						.el-tag {
							margin-left: 10px;
						}

						.button-new-tag {
							margin-left: 10px;
							height: 32px;
							line-height: 30px;
							padding-top: 0;
							padding-bottom: 0;
							width: 150px;
						}

						.input-new-tag {
							width: 150px;
							margin-left: 10px;
							vertical-align: bottom;
						}

						.create-box-poster-tips {
							margin-top: 26px;
							font-size: 14px;
							color: #89898c;

							.create-box-poster-tips__title {
								margin: 20px 0;
								color: #0088ff;
							}

							p {
								line-height: 25px;
							}
						}

						.avatar-uploader {
							.el-upload {
								border: 1px dashed #d9d9d9;
								border-radius: 6px;
								cursor: pointer;
								position: relative;
								overflow: hidden;
							}

							.el-upload:hover {
								border-color: #409EFF;
							}
						}

						.avatar-uploader-icon {
							font-size: 28px;
							color: #8c939d;
							width: 328px;
							height: 200px;
							line-height: 200px;
							text-align: center;
						}

						.avatar {
							width: 328px;
							height: 200px;
							display: block;
						}

						.el-input--suffix {
							.el-input__inner {
								width: 100%;
								height: 100%;
							}
						}

						.line {
							text-align: center;
						}

						.ql-editor {
							height: 400px;
						}
					}

				}

				.base-info-submit {
					margin-top: 40px;
					text-align: center;

					.el-button {
						padding: 12px 60px;
					}

					.create-checkbox {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 20px;

						span {
							font-size: 14px;
							color: #201f25;
							display: inline-block;
							position: relative;
							cursor: pointer;
							user-select: none;
							padding-left: 5px;

							.create-text {
								font-size: 14px;
								color: #0088ff;
								cursor: pointer;
							}
						}
					}
				}
			}
		}
	}
</style>
