<template>
	<div id="expert_detail">
		<div class="menu-nav">
			<div class="container">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/expert' }">专家列表</el-breadcrumb-item>
					<el-breadcrumb-item>专家详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="banner-expert-detail"></div>
		<div class="container">
			<div class="detail-content clearfix">
				<div class="detail-content-fl fl">
					<div class="touxiang">
						<el-image v-if="data.img" style="width: 130px; height: 130px" :src="data.img" fit="cover">
						</el-image>
						<el-image v-else-if="data.expert_user_info&&data.expert_user_info.avatar"
							style="width: 130px; height: 130px" :src="data.expert_user_info.avatar" fit="cover">
						</el-image>
						<el-image v-else style="width: 130px; height: 130px">
							<div v-if="data.name" slot="error" class="image-slot">
								{{data.name.substr(0,1)}}
							</div>
						</el-image>
					</div>
					<p><span class="expertname">{{data.name}}</span> {{data.zhicheng}}</p>
					<!-- <p><template v-if="data.city"> {{data.province}} {{data.city}} </template>
						<template
							v-else-if="data.expert_user_info&&data.expert_user_info.city">{{data.expert_user_info.city}}</template>
					</p> -->
					<p>{{data.post}}</p>
					<el-popover placement="bottom" style="width: 106px;" trigger="click">
						<el-image style="width: 150px; height: 150px" :src="mp_code" fit="cover"></el-image>
						<button class="contact" slot="reference">联系专家</button>
					</el-popover>
					<!-- <a target="_blank" href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09">
						<button class="contact">联系专家</button>
					</a> -->
				</div>
				<div class="detail-content-fr fr">

					<div class="intro clearfix">
						<ul>
							<li>
								<div class="icon icon-type">学科分类：<template
										v-if="data.subject">{{data.subject.cate_one}} &nbsp; {{data.subject.cate_two}}</template>
								</div>
							</li>
							<li>
								<div class="icon icon-category">产业领域：{{data.territory_one_name}}
									{{data.territory_two_name}}</div>
							</li>
							<li>
								<div class="icon icon-search">擅长方向：<span v-for="(i,k) in data.tag"
										:key="k">{{i}}</span></div>
							</li>
						</ul>
						<ul>
							<li>
								<div class="icon icon-diqu" v-if="data.province">所 在 地：
									<template v-if="data.city"> {{data.province}} {{data.city}} </template>
								</div>
								<div class="icon icon-diqu" v-else>所 在 地：<span
										v-if="data.expert_user_info">{{data.expert_user_info.city}}</span> </div>
							</li>
							<li>
								<div class="icon icon-unit">所属院校/单位：{{data.danwei}}</div>
							</li>
						</ul>
					</div>
					
				</div>
				<!-- <div class="scan-box share-box">
					<span class="btn-star btn-collection " @click="Collect"><i class="icon"
							:class="collect?'icon-starxz':'icon-star'"></i> 收藏</span>
				</div> -->
				
			</div>
			<div class="intro-detail">
				<div class="intro-detail-item" v-if="data.profile">
					<div class="tit">专家介绍</div>
					<p>{{data.profile}}</p>
				</div>


				<div class="intro-detail-item" v-if="data.yjycg">
					<div class="tit">项目案例</div>
					<p>{{data.yjycg}}</p>
				</div>
				<div class="intro-detail-item" v-if="data.jlry">
					<div class="tit">荣誉奖项</div>
					<p v-html="data.jlry"></p>
				</div>
				<div class="intro-detail-item" v-if="data.shfw">
					<div class="tit">社会服务</div>
					<p>{{data.shfw}}</p>
				</div>
				<div class="intro-detail-item" v-if="data.zlcg">
					<div class="tit">代表成果</div>
					<p>{{data.zlcg}}</p>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
	import flexRight from "@/components/flexRight.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components:{
			flexRight
		},
		data() {
			return {
				id: '',
				data: {},
				expert_list: [],
				collect: false,
				mp_code: '',
			}
		},
		mounted() {
			var querys = this.$route.query
			this.id = querys.id
			this.detail();
			this.get_expert_list();
		},
		methods: {
			get_mpcode() {
				this.$http.post('expert/mp_code', {
					id: this.data.id
				}).then(res => {
					this.mp_code = res.data.path;
					this.$forceUpdate();
				}).catch(err => {
					console.log(err)
				})
			},
			get_expert_list() {
				this.$http.post('expert/list', this.expert_search_data).then(res => {
					let data = res.data;
					this.expert_list = data;
				}).catch(err => {
					console.log(err)
				})
			},
			detail() {
				this.$http.post('expert/detail', {
					id: this.id
				}).then(res => {
					let data = res.data;
					this.data = data;
					if (Array.isArray(res.data.img)) {
						this.data.img = res.data.img[0];
					}
					this.get_mpcode();
				}).catch(err => {
					console.log(err)
				})
			},
			Collect() {
				this.collect = !this.collect;
			}
		}
	}
</script>
<style lang="less" src="../../assets/css/expert.less"></style>
