<template>
	<div id="detail">
		<div class="container">
			<!-- 面包宵 搜索-->
			<div class="column-search">
				<!-- 面包屑导航-->
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/achievement' }">技术列表</el-breadcrumb-item>
					<el-breadcrumb-item>技术详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 详情图片，详情属性-->
			<div class="detail-info">
				<div class="detail-slide">
					<div class="slide-warper">
						<el-image v-if="data.img" style="width: 360px; height: 240px"  :src="data.img[index]" fit="cover"></el-image>
					</div>
					<div class="thumb-warper">
						<div class="prev" @click="prev"></div>
						<div class="next" @click="next"></div>
						<div class="little_img">
							<ul ref="imgList" >
								<li  :class="index == i ? 'on' :''" v-for="(item,i) in data.img" :key="i" @click='getIndex(i)'>
									<el-image style="width: 74px; height: 54px" :src="item" fit="cover"></el-image>
								</li>
							</ul>
						</div>
					</div>
					<div class="share-box">
						<!-- collection -->
						<span class="btn-star" @click="do_Collect"><i :class="data.is_collect ? 'el-icon-star-on':'el-icon-star-off'"></i>
							收藏</span>
						<span class="span-share"><i class="el-icon-share"></i> 分享</span>
					</div>
				</div>
				<div class="detail-prop">
					<h2 class="artname">{{data.title}}</h2>
					<!-- <p> <span class="text-brown">专利(申请号)：</span></p> -->
					<p class="fl width-50"><span class="text-brown">专利状态：</span>有</p>
					<p class="fl width-50">
						<span class="text-brown">标签：</span>
						<span style="margin-right: 10px;" v-for="(item,i) in data.tag" :key="i">{{item}}</span>
					</p>
					<p class="fl width-50" v-if="data.finish"><span class="text-brown">第一完成单位：</span>{{data.finish.unit}}</p>
					<!-- <p class="fl width-50"><span class="text-brown">所在地：</span>内蒙古-鄂尔多斯 </p> -->
					<p class="fl width-50"><span class="text-brown">最近更新：</span>{{data.time}}</p>
					<p class="fl width-50" v-if="data.finish"> <span class="text-brown">成果完成人：</span>{{data.finish.people}}</p>
					<!-- <p class="fl width-50"> <span class="text-brown">交易方式：</span>面议</p> -->
					<p> <span class="text-brown">价格：{{data.reference_price}}</span></p>
					<div class="detail-btn">
						<a target="_blank" href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09">
						<div class="btn">我要购买</div>
						</a>
					</div>
					<el-dialog title="立即咨询" :visible.sync="centerDialogVisible" width="20%" center>
						<el-form :model="form">
							<el-form-item>
								<el-input v-model="form.name" autocomplete="off" placeholder="我们该如何称呼您"></el-input>
							</el-form-item>
							<el-form-item>
								<el-input v-model="form.phone" autocomplete="off" placeholder="请留下您的联系方式以便顾问能联系到您"></el-input>
							</el-form-item>
						</el-form>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="centerDialogVisible = false">立即咨询</el-button>
						</span>
					</el-dialog>
				</div>
			</div>
			<!-- 免责声明-->
			<div class="clearfix">
				<div class="main">
					<div class="tabs-container">
						<el-tabs type="border-card" @tab-click="move(1)">
							<el-tab-pane label="技术详情">
								<div class="tab-content">
									<div class="tab-pane-detail">
										<!-- 项目简介-->
										<div class="detail-item" id="item1">
											<h4 class="detail-title"> <span>项目简介</span></h4>
											<div class="detail-content" v-html="data.xmhjsqk">
											</div>
										</div>
										<div class="detail-item" id="item1">
											<h4 class="detail-title"> <span>应用领域</span></h4>
											<div class="detail-content" v-html="data.yyly">
											</div>
										</div>

										<!-- 交易安全保障-->
										<div class="detail-item" id="item2">
											<h4 class="detail-title"> <span>交易安全保障</span></h4>
											<div class="detail-content">
												<p>1、买家交易款项将暂保管于科技牛网担保账户，科技牛网全程资金代管，保障买卖双方资金安全。</p>
												<p>2、选择委托经纪人服务，均由交易经纪人验证交易品和买卖双方身份信息真实有效性。</p>
												<p>3、科技牛网专属律师事务所将对委托交易全程监督，确保所有交易合同及相关文件合法有效。</p>
												<p>4、选择委托经纪人服务，并由科技牛网向卖家代付定金；待国家知识产权局下发手续合格通知书、并经科技牛网核实后，支付卖家尾款。</p>
												<p>5、选择委托经纪人服务，将由交易经纪人代办国家手续，客户随时可查进度。</p>
												<p>6、免责声明：部分数据来源于网络。</p>
											</div>
										</div>
									</div>
								</div>
							</el-tab-pane>
							<el-tab-pane label="交易安全保障" disabled></el-tab-pane>
						</el-tabs>
					</div>
					<!-- 猜您感兴趣的-->
					<div class="panel">
						<div class="panel-head">
							<h4 class="text-brown font-18">猜您感兴趣的</h4>
						</div>
						<div class="panel-body">
							<ul class="interest-list clearfix likes">
								<li v-for="(v,k) in achievemnet" @click="click_achi(v.id)" :key="k">
									<div class="a">
										<div class="img-box">
											<el-image style="width: 207px; height: 138px" :src="v.img[0]" fit="cover"></el-image>
										</div>
										<span>{{v.title}}</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="sidebar">
					<div class="advertisement">
						<div class="a">
							<router-link to="/soar">
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/gqrd.jpg" alt="高新技术企业认定,担保申请，省心有保障">
							</router-link>
						</div>
						<div class="a">
							<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/srpg.jpg" alt="双软评估,招投标门槛,软件企业评估,软件产品评估">
						</div>
					</div>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
	import flexRight from "@/components/flexRight.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			flexRight
		},
		data() {
			return {
				index: 0,
				collect: false,
				centerDialogVisible: false,
				form: {
					name: '',
					phone:''
				},
				data: {},
				id: '',
				achievemnet_post: {
					limit: 4,
					page: 1,
					type: 'hot',
				},
				achievemnet: []
			}
		},
		computed: {

		},
		watch: {
			$route(to) {
				if (to.path == '/achivement/detail') {
					this.id = to.query.id;
					this.get_detail();
					this.demendList();
					this.$nextTick(function() {
						document.querySelector('#app').scrollIntoView({
							behavior: "auto"
						});
					})

				}
			},
		},
		mounted() {
			this.id = this.$route.query.id;
			this.get_detail();
			this.demendList();
		},
		methods: {
			click_achi(id) {
				this.$router.push({
					path: '/achivement/detail',
					query: {
						id: id
					}
				})
				// this.id = id;
				/* this.get_detail();
				 */
			},
			async demendList() {
				await this.$http.post('achievement/list', this.achievemnet_post).then(res => {
					if (res.code == 200) {
						this.achievemnet = res.data;
					}
				}).catch(err => {
					console.log(err);
				})
			},
			get_detail() {
				this.$http.post('achievement/detail', {
					id: this.id
				}).then(res => {
					if (res.code == 200) {
						this.data = res.data;
						console.log(res.data)
					}
				}).catch(err => {
					console.log(err);
				})
			},
			getIndex(i) {
				this.index = i;
			},
			move(key) {
				// 获取点击的按钮对应页面的id
				var PageId = document.querySelector('#item' + key)
				// 打印出对应页面与窗口的距离
				console.log(PageId.offsetTop)
				// 使用平滑属性，滑动到上方获取的距离
				// 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
				// widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
				window.scrollTo({
					'top': PageId.offsetTop,
					'behavior': 'smooth'
				})
			},
			prev() {
				if (this.index <= 0) {
					this.index = this.imgUrl.length - 1;
				} else {
					this.index--
				}
			},
			next() {
				if (this.index >= this.data.img.length - 1) {
					this.index = 0;
				} else {
					this.index++
				}
				if (this.index > 3) {
					this.$refs.imgList.style.right = 80 + 'px';
				}
			},
			do_Collect() {

				this.$http.post('collect/add', {
					type: 2,
					collect_id: this.id,
					is_collect: this.data.is_collect
				}).then(res => {
					if (res.code == 200) {
						this.data.is_collect = res.data.is_collect;
					} else if (res.code == 401) {
						this.$router.push({
							path: '/loagin'
						})
					} else {
						this.$message.info(res.msg)
					}
				}).catch(err => {
					console.log(err)
				})
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/achievement.less">
