<template>
	<div id="shop">

		<Top v-if="data.name" :data="data" :TabCur="2"></Top>
		<div class="new-shop-index shop-case about-shop">
			<!-- bottom -->
			<div class="w1200 margin-top-20 clearfix">
				<!-- left -->
				<Left :data="data" :id="id"></Left>
				<!-- Cur3 公司介绍 -->
				<div class="right-panel fl margin-left-20" v-if="TabCur==2">
					<div class="about-detail">
						<p class="title f16 text-bold">公司介绍</p>
						<div class="margin-top-20 company-intro">
							{{data.introduction}}
						</div>

						<p class="title f16 text-bold margin-top-40">工商信息</p>
						<table class="pattern margin-top-20">
							<tbody>
								<tr>
									<td>公司名称</td>
									<td>{{data.name}}</td>
									<td>注册号</td>
									<td>320583001127642</td>
								</tr>
								<tr>
									<td>企业职务</td>
									<td>{{data.contact_post}}</td>
									<td>企业代表</td>
									<td>{{data.contact_name}}</td>
								</tr>
								<tr>
									
									<td>注册地</td>
									<td colspan="3">{{data.province}}{{data.city}}{{data.area}}</td>
								</tr>
							</tbody>
						</table>


					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import Top from "./top.vue"
	import Left from "./left.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		data() {
			return {
				id: '',
				title_list: [{
					title: '店铺首页',
					path: '/store/index'
				}, {
					title: '最新产品',
					path: '/store/anli'
				}, {
					title: '关于我们',
					path: '/store/about'
				}, {
					title: '留言咨询',
					path: '/store/leave'
				}],
				TabCur: 2,
				data: {},
				new_product: {
					list: [],
					limit: 8,
					page: 1,
					count: 0,
				}
			}
		},
		components: {
			Top,
			Left
		},
		computed: {},
		mounted() {
			let query = this.$route.query;
			this.id = query.id;
			this.get_detail();
			this.get_new_product()

			if (window.history && window.history.pushState) {
				// 向历史记录中插入了当前页
				history.pushState(null, null, document.URL);
				window.addEventListener('popstate', this.goBack, false);
			}
		},
		destroyed() {
			window.removeEventListener('popstate', this.goBack, false);
		},
		methods: {
			goBack() {
				// console.log("点击了浏览器的返回按钮");
				sessionStorage.clear();
				window.history.back();
			},
			submit() {
				this.$http_qm.post('leave_message/add', this.form).then(res => {
					this.$message(res.msg);
				}).catch(err => {
					console.log(err);
				})
			},
			get_new_product() {
				this.$http_qm.post('product/list', {
					limit: this.new_product.limit,
					page: this.new_product.page
				}).then(res => {
					this.new_product.list = res.data;
					this.new_product.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
				}).catch(err => {
					console.log(err);
				})
			},
			tabSelect(url) {
				this.$router.push({
					path: url
				})
			},
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
			},
			projectCase() {
				this.leave(2);
			},
			leave(value) {
				if (value == 1) {
					this.TabCur = 0;
					this.Leave = true
				} else if (value == 2) {
					this.TabCur = 1;
					this.Case = true;
				} else {
					this.Leave = false;
				}

			}
		}
	}
</script>
<style type="text/css" lang="less" src="../../assets/css/store.less"></style>
