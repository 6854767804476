<template>
	<div id="shop">
		<Top v-if="data.name" :data="data" :TabCur="3"></Top>

		<div class="new-shop-index shop-case about-shop">
			<!-- bottom -->
			<div class="w1200 margin-top-20 clearfix">
				<!-- left -->
				<Left :data="data" :id="id"></Left>
				<!-- 留言 -->
				<div class="right-panel fl margin-left-20 margin-bottom-40">
					<div class="leave-message">
						<p class="f16 text-bold" >留言咨询</p>
						<el-form :rules="rules" ref="ruleForm" label-position="right" label-width="80px" :model="form">
							<el-form-item label="主题" prop="title">
								<el-input v-model="form.title"></el-input>
							</el-form-item>
							<el-form-item>
								<el-checkbox-group v-model="form.type">
									<el-checkbox :label="v" v-for="(v,k) in type_arr" :key="k" :value="v"></el-checkbox>

								</el-checkbox-group>
							</el-form-item>
							<el-form-item label="详细描述" prop="describe">
								<el-input type="textarea" v-model="form.describe"></el-input>
							</el-form-item>
							<el-form-item label="公司名称" prop="contact_company_name">
								<el-input v-model="form.contact_company_name"></el-input>
							</el-form-item>
							<el-form-item label="联系人" prop="contact_name">
								<el-input v-model="form.contact_name"></el-input>
							</el-form-item>
							<el-form-item label="联系电话" prop="contact_tel">
								<el-input v-model="form.contact_tel"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button @click="submit()">立即提交</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import Top from "./top.vue"
	import Left from "./left.vue"
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Top,Left
		},
		data() {
			return {
				id: '',
				data: {},
				form: {
					title: '',
					type: [],
					describe: '',
					contact_company_name: '',
					contact_name:'',
					contact_tel:'',
					company_id:'',
				},
				type_arr: ['了解最新产品',
					'寻求解决方案',
					'咨询技术问题',
					'咨询产品报价',
					'其它'
				],
				new_product:{
					list:[],
					limit:8,
					page:1,
					count:0,
				},
				rules: {
					title: [{
							required: true,
							message: '请输入活动名称',
							trigger: 'blur'
						}
					],
					describe: [{
							required: true,
							message: '请输入详细描述',
							trigger: 'blur'
						}
					],
					contact_company_name: [{
							required: true,
							message: '请输入公司名称',
							trigger: 'blur'
						}
					],
					contact_name: [{
							required: true,
							message: '请输入联系人',
							trigger: 'blur'
						}
					],
					contact_tel: [{
							required: true,
							message: '请输入联系电话',
							trigger: 'blur'
						},{ min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
						{
							pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
							message: "请输入正确的手机号码"
						}
					],
					region: [{
						required: true,
						message: '请选择活动区域',
						trigger: 'change'
					}],
					date1: [{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '请至少选择一个活动性质',
						trigger: 'change'
					}],
					resource: [{
						required: true,
						message: '请选择活动资源',
						trigger: 'change'
					}]
				},
				product_id:'',
			}
		},
		computed: {},
		mounted() {
			let query = this.$route.query;
			this.id = query.id;
			 if(typeof(query.product_id) != 'undefined'){
				this.product_id = query.product_id;
			 }
			this.get_detail();
			this.get_new_product()
		},
		methods: {
			submit(){
				   this.$refs["ruleForm"].validate((valid) => {
				          if (valid) {
				           this.form.company_id = this.id
						   this.form.product_id = this.product_id;
						   let data = this.form
						   data.contact_tel = Number(data.contact_tel)
				           this.$http_qm.post('leave_message/add', data).then(res => {
				           	  this.$message(res.msg);
							  if(res.code == 200){
								  this.$router.go(-1)
							  }
				           }).catch(err => {
				           	console.log(err);
				           })
				          } else {
				            console.log('error submit!!');
				            return false;
				          }
				        });
				
			},
			get_new_product(){
				this.$http_qm.post('product/list', {
					limit: this.new_product.limit,page:this.new_product.page
				}).then(res => {
					this.new_product.list = res.data;
					this.new_product.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			get_detail() {
				this.$http_qm.post('company/detail', {
					id: this.id
				}).then(res => {
					this.data = res.data;
					console.log(this.data)
				}).catch(err => {
					console.log(err);
				})
			},
			tabSelect(url) {
				this.$router.push({
					path:url
				})
			},
		}
	}
</script>
<style type="text/css" lang="less" src="../../assets/css/store.less"></style>
