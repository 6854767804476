<template>
	<div id="app">
		<HeaderTop :path="path" v-show="!(path === '/move' ||path === '/data'|| path === '/news/add')"></HeaderTop>
		<router-view></router-view>
		<Bottom
			v-show="!(path ==='/login'||path ==='/register'||path ==='/data'||path ==='/prize_draw' || path === '/news/add' || path === '/move') ">
		</Bottom>
	</div>
</template>



<script>
	import HeaderTop from "./components/headerTop.vue"
	import Bottom from "./components/notify.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'App',
		components: {
			HeaderTop,
			Bottom
		},
		beforeRouteEnter() {
			this.head_show = false
		},
		computed: {
			...mapGetters(['city', 'user'])
		},
		watch: {
			$route(to) {
				// console.log(to);
				this.path = to.path
				// console.log(this.path);
				/* 	var index = this.path.indexOf("/", 1)
					if (index > 0) {
						var first = this.path.slice(0, index)
					}

					if (this.not_path.includes(this.path) || (index > 0 && this.first_path.includes(first))) {
						this.head_show = false
					} else {
						this.head_show = true
					} 
					this.$forceUpdate() */
			}
		},
		data() {
			return {
				path: '',
				head_show: false,
				not_path: ['/login', '/register', '/prize_draw', '/expert'],
				first_path: ['/store', '/expert'],
			}
		},
		created() {
			this.path = this.$route.path;
		},
		mounted() {
			this.path = this.$route.path
		},

		methods: {},
	}
</script>
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
<style>
	body {
		padding: 0 !important;
	}
</style>
