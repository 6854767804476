<template>
	<div id="case_detail">
		<div class="container flex flex-column">

			<!--面包屑end-->
			<div class="column-search">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item :to="{ path: '/case' }">成功案例</el-breadcrumb-item>
					<el-breadcrumb-item>详情</el-breadcrumb-item>
				</el-breadcrumb>
			</div>


			<div class="box2 flex">
				<div class="fl">
					<h2>{{data.title}} <span class="iconfont icon-re" v-if="data.is_top"></span></h2>
					<div class="tag flex">
						<div class="li" v-for="(v,k) in data.tag" :key="k">{{v}}</div>
					</div>
					<div class="flex space-between box3 padding-top-10">
						<div class="cate_name">
							{{data.cate_name}}
						</div>
						<div class="view_num">阅读 {{data.view_num}}</div>
					</div>
					<div class="content ql-container ql-editor" v-html="data.content">

					</div>
					<div class="support_num" @click="support_add()" :class="{active:data.is_support}">
						<span class="iconfont icon-dianzan1"></span> {{data.support_num}}
					</div>
				</div>
				<div class="fr">
					<div class="user">
						<img src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/app/logo_60x60.png" alt="">
						<div class="name">科技牛</div>
						<div class="post">资深顾问专家</div>
					</div>
					<div class="form">
						<el-input v-model="form.name" placeholder="请输入您的姓名"></el-input>
						<el-input type="textarea" :maxlength="200" :rows="7" placeholder="简单描述一下您的需求"
							v-model="form.content">
						</el-input>
						<button @click="onSubmit">提交</button>
					</div>
					<div class="next" v-if="data.next_data">
						<h2>下一篇</h2>
						<div class="flex" @click="href({path:'/case/detail',query:{id:data.next_data.id}})">
							<el-image style="width: 151px; height: 113px" v-if="data.next_data.img"
								:src="data.next_data.img[0]" fit="cover">
							</el-image>
							<div class="col2">
								<div class="title">{{data.next_data.title}}</div>
								<div class="tag"> <span v-for="(v,k) in data.next_data.tag" :key="k"> {{v}} <b
											v-if="k<data.next_data.tag.length-1">/</b> </span> </div>
								<div class="cate_name">
									{{data.next_data.cate_name}}
								</div>
							</div>
						</div>
					</div>
					<div class="recommend">
						<h2>推荐案例</h2>
						<ul>
							<li v-for="(v,k) in recommend_list" :key="k"
								@click="href({path:'/case/detail',query:{id:v.id}})">
								{{v.title}}
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<flexRight></flexRight>
	</div>
</template>

<script>
	import config from '@/config'
	var wsUrl = process.env.NODE_ENV === 'development' ? config.wsUrl.dev : config.wsUrl.pro;
	import flexRight from "@/components/flexRight.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			flexRight
		},
		data() {
			return {
				centerDialogVisible: false,
				data: {},
				form: {
					name: '',
					content: '',
					id: '',
				},
				param: {
					limit: 10,
					page: 1,
					status: 1,
					sort: 'view_num',
					tag: [],
				},
				recommend_list: [],
				upload_data: {
					dir: 'case'
				},
				formLabelWidth: '100px',
				fileList: [],
				id: '',
				next_id: '',
				dialogVisible: false,
				textarea: '',
				chat_post: {
					page: 1,
					limit: 1000,
					room_id: '',
				},
				next_data: [],
				room_id: '',
				to_uid: '',
				message: '',
				ws: null,
				config: {
					title: '科技牛需求分享',
					description: '科技牛最新需求链接内容详情分享',
					url: 'http://pc.keji6.cn/#/case/detail?id=' + this.id,
					source: 'http://pc.keji6.cn/#/case/detail?id=' + this.id,
					image: 'http://hellofood.fun/webicon.png',
					// summary : '吃货召唤', //相当于description
					// sites   : ['qzone', 'qq', 'weibo','wechat', 'douban'], // 启用的站点
					disabled: ['google', 'facebook', 'twitter', 'linyin'], // 禁用的站点
					wechatQrcodeTitle: "微信扫一扫：分享", // 微信二维码提示文字
					wechatQrcodeHelper: '立即下载发送专属二维码,邀请朋友加入',
				}
			}
		},
		computed: {
			...mapGetters(['user'])
		},
		watch: {
			$route(to) {
				this.id = to.query.id;
				this.get_detail()
			},
		},
		mounted() {
			let param = this.$route.query;
			this.id = param.id;
			this.get_detail()
		},

		methods: {

			support_add() {
				if (this.user == "") {
					this.$router.push({
						path: '/login'
					})
				}
				this.$http_qm.post('support/add', {
					support_id: this.id,
					type: 6,
					to_uid: this.data.uid,
					is_support: this.data.is_support,
				}).then(res => {
					if (res.code == 200) {
						this.data.is_support = res.data.is_support;
						if (this.data.is_support) {
							this.data.support_num += 1
						} else {
							this.data.support_num -= 1
						}
					} else if (res.code == 501) {
						this.$router.push({
							path: '/login'
						})
					}
				}).catch(err => {
					console.log(err)
				})
			},
			onSubmit() {
				console.log(this.user);
				if (this.user == null || typeof(this.user.id) == 'undefined') {
					this.$router.push({
						path: '/login'
					})
					return false;
				}
				if (this.form.name == '') {
					this.$message.error('请输入您的姓名')
					return false;
				}
				if (this.form.content == '') {
					this.$message.error('请输入您的需求')
					return false;
				}
				this.form.id = this.id,
					this.$http_qm.post('form/add', {
						info: this.form,
						type: 4,
					}).then(res => {
						this.$message.info(res.msg)
						if (res.code == 200) {
							this.form.content = '';
							this.form.name = '';
						}
					}).catch(err => {
						console.log(err)
					})
			},
			href(url) {
				this.$router.push(url)

			},
			get_hot_list() {
				this.$http_qm.post('case/list', this.param).then(res => {
					this.recommend_list = res.data;
				}).catch(err => {
					console.log(err);
				})
			},
			case_href(id) {
				this.id = id
				this.get_detail()
				this.get_hot_case();
			},
			get_chat_list() {
				console.log('room_id', this.room_id);
				if (this.room_id == '') {
					return
				}
				this.chat_post.room_id = this.room_id
				this.$http.post('chat/list', this.chat_post).then(res => {

					if (res.data != null) {
						this.chat = res.data
						// this.chat_post.page += 1
					} else {
						// this.chat.loading = false
					}
				}).catch(function(error) {
					console.log(error)
				})
			},
			send() {

				let send_query = {
					'method': 'chat.emit',
					'params': {
						'message': this.message,
						'uid': this.user.id,
						'to_uid': this.data.uid,
						'room_id': this.room_id
					},
					'id': this.room_id
				}
				send_query = JSON.stringify(send_query)
				this.ws.send(send_query)
			},

			webSocket_init() {

				if ('WebSocket' in window) {
					console.log('您的浏览器支持 WebSocket!')
					let ws_url = wsUrl
					this.ws = new WebSocket(ws_url)
					// 连接成功建立的回调方法
					this.ws.onopen = this.onopen
					this.ws.onmessage = this.onmessage
					// 连接发生错误的回调方法
					this.ws.onerror = this.onerror
					// 连接关闭的回调方法
					this.ws.onclose = this.onclose
				} else {
					// 浏览器不支持 WebSocket
					this.$message.info('您的浏览器不支持 WebSocket!')
				}
			},
			onclose() {
				// 关闭 websocket
				console.log('连接已关闭...')
			},
			onerror(e) {
				console.log('websocket服务出错了---onerror', e)
			},
			onopen() {
				this.join_room()
			},
			onmessage(res) {
				let data = JSON.parse(res.data)
				this.chat.list.push(data.result)
				this.$nextTick(() => {
					this.message = ''
				})
			},

			get_room_id() {
				this.$http.post('room/get_id', {
					to_uid: this.data.uid
				}).then(res => {
					if (res.code == 200) {
						this.room_id = res.data.id;
						this.webSocket_init()

						this.get_chat_list();
					}
				}).catch(function(error) {
					console.log('error')
					console.log(error)
				})
			},
			join_room() {
				let add_room_data = {
					'method': 'join.room',
					'params': {
						'roomid': this.room_id,
						'uid': this.data.uid,
					},
					id: this.room_id
				}
				// id 必须存在 否则回报错
				add_room_data = JSON.stringify(add_room_data)
				this.ws.send(add_room_data)
				console.log('加入房间' + this.room_id)
			},
			bShare() {

			},
			handleClose(done) {
				this.$confirm('确认关闭？')
					.then(() => {
						done();
					})
					.catch(() => {});
			},
			upload_success(response, file, fileList) {
				console.log(response);
				this.form.annex.push(response.data.img_url);
				console.log(file);
				console.log(fileList);
			},
			submit_scheme() {
				this.centerDialogVisible = false;
				/* 	let config = {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
					};
					let formData = new FormData();
					formData.append("file", '111'); */
				console.log(this.form);
				this.$http_qm.post('scheme/add', this.form).then(res => {
					this.$message.info(res.msg);
				}).catch(err => {
					console.log(err)
				})
			},

			get_detail() {
				var param = {
					id: this.id
				}
				param.request_type = 'next'
				this.$http_qm.post('case/detail', param).then(res => {
					if (res.code == 200) {
						this.data = res.data;
						this.get_hot_list();
					}
				}).catch(err => {
					console.log(err)
				})
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePreview(file) {
				console.log(file);
			},

		}
	}
</script>

<style lang="less">
	@import "../../assets/font/iconfont.css";
	@import "../../assets/css/edit.css";

	#case_detail {
		.container {
			width: 1220px;
			margin: 33px auto 49px;
		}

		.box2 {
			clear: both;
			.fl {
				width: 817px;

				h2 {
					font-size: 32px;
					font-weight: bold;
					color: #333333;
					margin: 36px 0px 0px;

					.icon-re {
						font-size: 24px;
						color: red;
					}
				}

				.tag {
					margin: 63px 0px 0px;

					.li {
						padding: 1px 6px;
						border: 1px solid #666666;
						border-radius: 13px;
						margin-right: 16px;
					}
				}

				.box3 {
					padding-bottom: 13px;
					border-bottom: 1px solid #F1F1F1;
				}

				.cate_name {
					font-size: 14px;
					font-weight: 400;
					color: #666666;
				}

				.view_num {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #666666;
				}

				.content {
					font-size: 16px;
					font-weight: 400;
					color: #333333;
					line-height: 28px;
					margin-top: 39px;
					word-break: break-all;
					white-space: break-spaces;

					/deep/ img {
						clear: both;
						margin-top: 10px;
					}
				}

				.support_num {
					display: flex;
					justify-content: center;
					margin: 39px auto 49px;
					width: 140px;
					height: 62px;
					line-height: 62px;
					border: 2px solid #666666;
					border-radius: 10px;
					font-size: 20px;
					font-weight: 500;
					cursor: pointer;
					text-align: center;

					&.active {
						background: #FFAF23;
						color: #FFFFFF;
						border: 2px solid #FFFFFF;
					}

					.icon-dianzan1 {
						padding-right: 21px;
						font-size: 20px;
					}
				}
			}

			.fr {
				width: 817px;
				width: 339px;
				margin-left: 63px;

				.next {
					h2 {
						margin-bottom: 20px;
					}

					border-bottom: 1px solid #F1F1F1;
					;
					border-top: 1px solid #F1F1F1;
					padding: 31px 0px;
					margin-bottom: 31px;

					.el-image {
						width: 151px;
						height: 113px;
						margin-right: 7px;
					}

					.title {
						width: 159px;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						height: 65px;
						word-break: break-all;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 3;
						overflow: hidden;
						cursor: pointer;
					}

					.tag {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #666666;
						line-height: 35px;
					}

					.cate_name {
						font-size: 12px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
					}
				}

				.user {
					text-align: center;

					img {
						width: 88px;
						height: 88px;
						border-radius: 50%;

					}

					.name {
						font-size: 20px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #333333;
						line-height: 45px;
					}

					.post {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #999999;
						line-height: 45px;
					}
				}

				.form {
					.el-input {
						margin: 28px 0px 18px;
					}

					button {
						margin: 22px auto 40px;
						cursor: pointer;
						display: block;
						width: 172px;
						height: 45px;
						line-height: 45px;
						background: #106AFF;
						border-radius: 10px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #FFFFFF;
						border: none;
						outline: none;
						text-align: center;
					}
				}

				.recommend {
					h2 {
						margin-bottom: 20px;
					}

					li {
						line-height: 30px;
						cursor: pointer;
						text-overflow: ellipsis;
						white-space: nowrap;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
						overflow: hidden;

						&::before {
							content: ".";
							font-size: 30px;
							color: #106AFF;
							padding-right: 5px;
						}

					}
				}
			}
		}
	}
</style>
