<template>
	<div id="admin">
		<div class="wrapper">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="main_right">
				<el-page-header @back="goBack" content="活动管理"></el-page-header>
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane v-for="(v,k) in tabItem" :key="k" :status="v.status" :label="v.label" :name="v.name">
						<div class="tabs-contents">
							<ul class="table-nav">
								<li class="hd-poster">活动海报</li>
								<li class="hd-title" style="flex:6">活动标题</li>
								<li class="hd-apply" style="flex:2">已报名</li>
								<li class="hd-time" style="flex:3">活动时间</li>
								<li class="hd-data" style="flex:3">发布时间</li>
								<li class="hd-state" style="flex:2">状态</li>
							</ul>
							<template v-if="activity.list.length>0">
								<div class="tabs-content" v-for="(v,k) in activity.list" :key="k">
									<div class="center-tabs-content">
										<div class="table-nav-content">
											<div class="content-img">
												<el-image style="width: 140px; height: 85px" :src="v.cover[0]"
													:alt="v.title" fit="cover"></el-image>
											</div>
											<div class="hd-title-content" style="flex:6">
												<router-link :to="{ path: '/activity/detail', query: { id: v.id } }"
													target="_blank">
													<p class="title-pp">{{v.title}}</p>
												</router-link>
												<div class="title-link">
													<!-- <div class="link-copyWrap">
													<span> http://hdxu.cn/FyX8P</span>
													<input class="link-copy" style="background: #fff;" type="button" value="复制">
												</div> -->
													<!-- <div class="down-code-new" data-shorturl="http://hdxu.cn/FyX8P" onclick="downLoadCode(this)">下载二维码</div> -->
													<a class="down-code-new down-code-newHref"
														style="position: absolute;z-index: -1;"
														id="download_wechat_qrcode-top" href=""
														download="4558212940011_wechat.png"></a>
												</div>
												<p class="hd-title-content-price">{{v.province}}{{v.city}}</p>
											</div>
											<div class="hd-apply-content" style="flex:2">{{v.join_num}}</div>
											<div class="hd-time-content flex" style="flex:3">
												<div class="div">
													<p>{{v.time_start}}</p>
													<span class="margin-span">到</span>
													<p>{{v.time_end}}</p>
												</div>
											</div>
											<div class="hd-data-content flex" style="flex:3">
												<p>{{v.created_at}}</p>
											</div>
											<div class="hd-state-content flex" style="flex:2">
												<p class="hd-end flex">{{v.status_mes}}
													<span class="tool-icon js-hd-end"></span>
												</p>
											</div>
										</div>
									</div>
									<div class="center-tabs-content-bottom">
										<ul class="center-tabs-contentnums">
											<li class="center-tabs-contentnum roster-managment">
												<div class="a">
													<router-link :to="{path:'/activity/add',query:{id:v.id}}">
														<i class="leftpi el-icon-edit"></i>编辑活动
													</router-link>
												</div>
											</li>
											<li class="center-tabs-contentnum graphical-statistic">
												<div class="a" slot="reference">
													<el-popconfirm title="这是一项活动确定删除吗？" @confirm="del(k,v.id)">
														<div slot="reference">
															<i class="leftpi el-icon-delete"></i>
															<span>删除</span>
														</div>
													</el-popconfirm>

												</div>
											</li>
										</ul>
										<div class="hd-right" style="display: none;">
											<span class="hd-clone">再次发布</span>
										</div>
									</div>
								</div>
							</template>
							<div class="user-event-admin-empty" v-else>
								<span></span>
								<div></div>
								<span class="a" @click="add">发布活动</span>
							</div>
							<div class="pagination">
								<el-pagination @current-change="handleCurrentChange" background
									layout="prev, pager, next" :page-size="activity_post.limit" :total="activity.count">
								</el-pagination>
							</div>
						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "../company/Left.vue"
	import {
		mapGetters
	} from 'vuex'
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left
		},
		computed: {
			...mapGetters(['user']),
		},
		data() {
			return {
				activeName: 'item1',
				tabItem: [{
					label: '全部',
					name: 'item1',
					status: 9,
				}, {
					label: '售票中',
					name: 'item2',
					status: 1,
				}, {
					label: '待审核',
					name: 'item3',
					status: 0,
				}, {
					label: '审核未通过',
					name: 'item4',
					status: 2,
				}],
				activity: {
					list: [],
					count: 0,
				},
				activity_post: {
					limit: 3,
					page: 1,
					status: 9,
					uid: '',
				},
			}
		},

		mounted() {
			if (typeof(this.user.id) != 'undefined') {
				this.activity_post.uid = this.user.id
			}
			this.company_detail();
		},

		methods: {
			company_detail() {
				this.$http_qm
					.post('company/detail', {
						type: 'me'
					}).then(res => {
						if (res.code == 200) {
							this.activity_post.company_id = res.data.id;
							this.get_list();
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
			del(k, id) {
				console.log(k, id)
				this.$http_qm.post('activity/delete', {
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.activity.list.splice(k, 1);
					}
				}).catch(err => {
					console.log(err)
				})

			},

			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.activity_post.page = val;
				this.get_list();
			},
			get_list() {

				if (this.activity_post.uid == '') {
					return false;
				}
				this.$http_qm.post('activity/list', this.activity_post).then(res => {
					this.activity.list = res.data;
					this.activity.count = res.count;
				}).catch(err => {
					console.log(err);
				})
			},
			handleClick(tab) {
				console.log(tab.$attrs);
				this.activity_post.status = tab.$attrs.status;
				this.get_list();
			},
			goBack() {
				console.log('go back');
			},
			add() {
				this.$router.push({
					path: '/activity/add'
				})
			}
		}
	}
</script>

<style lang="less">
	#admin {
		margin: 0;
		padding: 0;
		background: #f0f0f0;

		.wrapper {
			padding: 15px 0;
			width: 1200px;
			margin: 0 auto;
			flex-direction: row;
			justify-content: space-between;
			display: flex;

			.main_left {
				width: 195px;
				height: auto;
				text-align: center;
			}

			.main_right {
				width: 973px;
				height: auto;
				min-height: 100%;
				float: right;
				border: 1px solid #e0e0e0;

				.el-page-header {
					font-size: 14px;
					color: #999;
					width: 100%;
					height: 48px;
					line-height: 48px;
				}

				.tabs-contents {
					background: #fff;

					.table-nav {
						display: flex;
						flex-direction: row;
						background: #f8f8f8;
						height: 50px;
						align-items: center;
						font-size: 14px;
						color: #333;
						padding: 0 15px;

						li {
							text-align: center;
							list-style: none;
							font-size: 14px;
							color: #333;
						}

						.hd-poster {
							width: 140px;
						}
					}

					.tabs-content {
						padding: 0px 15px;
						border-bottom: 10px solid #f0f0f0;
						transition: all 0.5s ease;

						.table-nav-content {
							margin-top: 20px;
							height: 85px;
							display: flex;
							flex-direction: row;

							.hd-poster-img {
								width: 140px;
								vertical-align: middle;
								border-style: none;
							}

							.hd-title-content {
								flex: 5;
								position: relative;
								display: flex;
								flex-direction: column;
								justify-content: space-between;

								.title-pp {
									font-family: PingFangSC-Medium;
									font-size: 14px;
									color: #0088ff;
									padding: 0 10px;
									line-height: 20px;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									font-weight: bold;
								}

								.title-link {
									font-size: 12px;
									color: #999;
									padding-left: 10px;
									display: flex;
									align-items: center;

									.link-copyWrap {
										display: flex;
										align-items: center;

										span {
											color: #333;
											display: inline-block;
											max-width: 135px;
											overflow: hidden;
											white-space: nowrap;
											text-overflow: ellipsis;
											font-size: 12px;
										}

										input {
											outline: none;
											border: none;
										}
									}

									.link-copy {
										color: #0088ff;
										margin-left: 13px;
										cursor: pointer;
										font-size: 12px;
									}

									.down-code-new {
										font-size: 12px;
										color: #0088ff;
										margin-left: 13px;
										margin-top: 2px;
										cursor: pointer;
									}
								}

								.hd-title-content-price {
									font-size: 12px;
									color: #333;
									padding: 0 10px;
								}
							}

							.hd-apply-content {
								font-size: 12px;
								color: #666;
								display: flex;
								justify-content: center;
								align-items: center;
							}

							.hd-time-content {
								flex: 3;
								font-size: 12px;
								color: #666;
								justify-content: center;
								align-items: center;
							}

							.hd-data-content {
								flex: 3;
								font-size: 12px;
								color: #666;
								justify-content: center;
								align-items: center;
							}

							.hd-state-content {
								flex: 2;
								font-size: 12px;
								color: #666;
								flex-direction: column;
								justify-content: center;
								align-items: center;

								.hd-end {
									align-items: center;
									color: #fd6767;
								}
							}
						}

						.center-tabs-content-bottom {
							position: relative;

							.center-tabs-contentnums {
								border-top: 1px solid #e5e5e5;
								display: flex;
								height: 50px;
								margin-top: 18px;

								.center-tabs-contentnum {
									display: flex;
									align-items: center;
									margin-right: 2%;
									list-style: none;

									.a {
										color: #666666;
										font-size: 13px;
										transition: all 0.3s ease;
										display: flex;
										align-items: center;
										cursor: pointer;

										.leftpi {
											font-size: 16px;
											color: #0088FF;
											margin-right: 10px;
										}
									}
								}
							}

							.hd-right {
								position: absolute;
								right: .2rem;
								top: 19px;
								color: #555555;
								font-size: 13px;
								font-family: PingFangSC-Regular;

								.hd-clone {
									cursor: pointer;
									color: #555555;
									font-size: 13px;
									padding: 2px 9px;
									border: 1px solid #888888;
									border-radius: 12px;
								}
							}
						}
					}

					.user-event-admin-empty {
						padding-top: 40px;
						text-align: center;
						height: 500px;
						position: relative;
						display: flex;
						flex-direction: column;

						div {
							height: 220px;
							background: url("https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/admin-empty.png") no-repeat center;
							display: inline-block;
						}

						.a {
							font-size: 20px;
							vertical-align: middle;
							color: #0088ff;
							cursor: pointer;
						}
					}

					.pagination {
						font-size: 14px;
						padding: 40px 0;
						display: flex;
						justify-content: center;
						text-align: center;
					}
				}
			}
		}
	}
</style>
