<template>
	<div id="qy_column">
		<el-tree :data="member ? data : data1" node-key="id" :default-expanded-keys="[1,2, 3,4,5,6,7,8,9]" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
	</div>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				member:false,
				data: [{
						id: 1,
						label: '账号管理',
						children: [{
							label: '基本信息',
							tourl: 'zcMessage'
						}, {
							label: '账号密码',
							tourl: 'zcPassword'
						}, {
							label: '账号验证',
							tourl: 'zcVerify'
						}, {
							label: '专家入驻',
							tourl: 'expertAdd'
						}]
					},
					{
						id: 2,
						label: '主页设置',
						disabled: true,
						children: [{
							label: '模板设置',
							tourl: 'templet'
						}, {
							label: '友情链接设置',
							tourl: 'linksList'
						},{
							label: '幻灯片设置',
							tourl: 'bannerList'
						}]
					}, 
					{
						id: 3,
						label: '企业信息',
						disabled: true,
						children: [{
							label: '企业信息',
							tourl: 'qyMessage'
						}, {
							label: '身份选择',
							tourl: 'qyStatus'
						}]
					}, {
						id: 4,
						label: '产品管理',
						disabled: true,
						children: [{
							label: '发布产品',
							tourl: 'cpProcess'
						}, {
							label: '产品管理',
							tourl: 'cpAdmin'
						}]
					}, {
						id: 5,
						label: '成果管理',
						disabled: true,
						children: [{
							label: '发布成果',
							tourl: 'achivementAdd'
						}, {
							label: '成果管理',
							tourl: 'achivementAdmin'
						}]
					},
					{
						id: 6,
						label: '新闻管理',
						disabled: true,
						children: [{
							label: '发布新闻',
							tourl: 'newsAdd'
						}, {
							label: '新闻管理',
							tourl: 'newsAdmin'
						}]
					}, {
						id: 7,
						label: '活动管理',
						disabled: true,
						children: [{
							label: '活动发布',
							tourl: 'activityAdd'
						}, {
							label: '活动管理',
							tourl: 'activityAdmin'
						}]
					}, {
						id: 8,
						label: '需求管理',
						disabled: true,
						children: [{
							label: '需求发布',
							tourl: 'demandAdd'
						}, {
							label: '需求管理',
							tourl: 'demandAdmin'
						}]
					}, {
						id: 9,
						label: '留言管理',
						disabled: true,
						children: [{
							label: '留言管理',
							tourl: 'store_message'
						}],
					}
				],
				data1: [{
						id: 1,
						label: '账号管理',
						children: [{
							label: '基本信息',
							tourl: 'zcMessage'
						}, {
							label: '账号密码',
							tourl: 'zcPassword'
						}, {
							label: '账号验证',
							tourl: 'zcVerify'
						}, {
							label: '专家入驻',
							tourl: 'expertAdd'
						}]
					},
					{
						id: 3,
						label: '企业信息',
						disabled: true,
						children: [{
							label: '企业信息',
							tourl: 'qyMessage'
						}]
					}
				],
				defaultProps: {
					children: 'children',
					label: 'label'
				}
			}
		},
		created() {
			this.get_detail();
		},
		methods: {
			handleNodeClick(data) {
				if (data.tourl != "" && data.tourl != undefined) {
					this.$router.push({
						name: data.tourl
					})
				}
			},
			get_detail() {
				this.$http_qm
					.post('company/detail', {
						type: 'me'
					}).then(res => {
						if (res.code == 200) {
							this.company_detail = res.data;
							if (res.data.status == 1 ) {
								this.member = true
							}
						}else if(res.code == 400){
							this.member = false
						}
					})
					.catch(err => {
						console.log(err);
					});
			},
		}
	}
</script>

<style lang="less">
	.el-tree {
		color: #333 !important;

		.el-tree-node__content {
			height: 35px !important;
		}
	}
</style>
