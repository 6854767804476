<template>
	<div class="top common_top">
		<div class="bd">
			<img class="logo" src="https://kejiniu.oss-cn-zhangjiakou.aliyuncs.com/pc/other/index3.jpg" />
			<div class="normal_div_1">
				<div class="normal_div_2">
					<ul>
						<li @click="find_cate_click(v.type)" v-for="(v, find_cate_index) in find_cate"
							:key="find_cate_index" :class="{ active: v.checked }">
							{{ v.name }}
						</li>
					</ul>
				</div>
				<div class="floorBgWrap">
					<input class="floorBg" v-model="search_text" placeholder="" type="text" />
					<div class="block_1" @click="do_search()">
						<div class="shopLogo"><span class="word_2">搜索</span></div>
					</div>
				</div>
				<span class="desc_1"></span>
			</div>
			<div class="normal_div_3">
				<div class="logo_1"></div>
				<span class="info_1">
					<router-link to="/demand/add">免费发布需求</router-link>
				</span>
			</div>
			<span class="word_3">或</span>
			<div class="normal_div_4">
				<div class="logo_2"></div>
				<a class="info_2" target="_blank"
					href="http://p.qiao.baidu.com/cps/chat?siteId=11939436&userId=25487969&siteToken=bce64c50ca792b2d58d2bb5861832e09">免费找官方推荐</a>
			</div>
		</div>
		<div class="submain">

			<el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
				<template v-for="(v, k) in nav">
					<el-submenu v-if="v.child&&v.child.length>0" :index="k+''"  :key="k">
						<template slot="title">{{ v.name }}</template>
						<el-menu-item v-for="(v2, k2) in v.child" :key="k2">
							<router-link :to="v2.to">{{v2.name}}</router-link>
						</el-menu-item>
					</el-submenu>

					<el-menu-item v-else :index="k+''" :key="k">
						<!-- <router-link :to="v.to">{{ v.name }}</router-link> -->
						<a @click="go_menu(v.to)">{{ v.name }}</a>
					</el-menu-item>
				</template>
			</el-menu>
			<!-- <ul>
				<li v-for="(v, k) in nav" :key="k" @click="nav_click(k, v.to)">
					<router-link :class="{ active: v.checked }" :to="v.to">{{ v.name }}</router-link>
				</li>
			</ul> -->
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		props: {
			search_text: {
				type: String,
				default: ''
			},
			page_type: {
				type: String,
			}
		},

		data() {
			return {
				activeIndex: '0',
				nav: [{
						name: '首页',
						checked: true,
						to: '/'
					},
					{
						name: '成果库',
						checked: false,
						to: '/achievement'
					},
					{
						name: '案例库',
						checked: false,
						to: '/case'
					},
					{
						name: '需求大厅',
						checked: false,
						to: '/demand'
					},
					{
						name: '企业联盟',
						checked: false,
						to: '/store'
					},
					{
						name: '找专家',
						checked: false,
						to: '/expert'
					},
					// {
					// 	name: '产品中心',
					// 	checked: false,
					// 	to: '/product'
					// },
					{
						name: '解决方案',
						checked: false,
						to: '/service',
						child: [{
								name: '企业服务',
								to: '/service'
							},
							{
								name: '政府服务',
								to: '/service/government'
							},
							{
								name: '科院服务',
								to: '/service/colleges'
							}
						]
					},
					{
						name: '会员权益',
						checked: false,
						to: '/rights'
					},
					{
						name: '国际站',
						checked: false,
						to: 'https://int.keji6.cn/'
					}
				],
				nav2: [{
						name: '登录',
						to: '/login'
					},
					{
						name: '注册',
						to: '/register'
					},
					{
						name: '我的科技牛',
						to: '/user_center'
					},
					{
						name: '企业入驻',
						to: '/company/qy_message'
					},
					{
						name: '专家入驻',
						to: ''
					},
					{
						name: '客服中心',
						to: ''
					},
					{
						name: '科技牛APP',
						to: ''
					}
				],
				find_cate: [{
						name: '找成果',
						checked: true,
						type: 'achievement',
					}, {
						name: '找案例',
						checked: false,
						type: 'case',
					},
					{
						name: '找需求',
						checked: false,
						type: 'demand',
					}, {
						name: '找企业',
						checked: false,
						type: 'company',
					},
					{
						name: '找专家',
						checked: false,
						type: 'expert',
					}
				],
				search_type: 0
			};
		},
		computed: {
			...mapState(['city'])
		},
		mounted() {
			console.log(this.page_type);
			if (parseInt(this.page_type) == 0) {
				this.find_nav_fill();
			} else {
				this.find_nav();
				this.find_nav_fill();
			}
		},
		watch: {
			$route(to) {
				console.log('我再导航栏')
				console.log(to);
			},
		},
		methods: {

			find_nav() {
				var page_type = this.page_type;
				this.search_type = page_type;
				this.find_cate.forEach(function(v) {
					if (v.type == page_type) {
						v.checked = true;
					} else {
						v.checked = false;
					}
				});
			},
			find_nav_fill() {
				var path = this.$route.path;
				
				let that = this;
				if (path == '/news' || path == '/activity') {
					console.log(path);
					this.activeIndex =  '';
					return false
				}
				this.nav.forEach(function(v, k) {

					if (v.to == path) {
						v.checked = true;
						that.activeIndex = k + '';
					} else {
						v.checked = false;
					}

				});
			},
			go_menu(url) {
				console.log(url);
				if (url.includes('https')) {
					window.open(url);
				} else {
					this.$router.push({
						path: url
					})
				}
			},
			do_search() {
				if (this.search_type == 'achievement') {
					this.$router.push({
						path: '/achievement',
						query: {
							keyword: this.search_text
						}
					});
				} else if (this.search_type == 'demand') {
					this.$router.push({
						path: '/demand',
						query: {
							keyword: this.search_text
						}
					});
					return;
				} else if (this.search_type == 'company') {
					this.$router.push({
						path: '/store',
						query: {
							keyword: this.search_text
						}
					});
					return;
				} else if (this.search_type == 'expert') {
					this.$router.push({
						path: '/expert',
						query: {
							keyword: this.search_text
						}
					});

				} else if (this.search_type == 'case') {
					this.$router.push({
						path: '/case',
						query: {
							keyword: this.search_text
						}
					});

				}
			},
			nav_click(k, to) {
				if (!to) {
					this.$message('功能暂未开发');
					return;
				}
				this.nav.forEach(function(v, k1) {
					if (k == k1) {
						v.checked = true;
					} else {
						v.checked = false;
					}
				});

			},
			href(url) {
				if (url) {
					this.$router.push({
						path: url,
						query: {}
					});
				} else {
					this.$message('功能暂未开发');
				}
			},
			find_cate_click(type) {
				this.search_type = type;
				this.find_cate.forEach(function(v) {
					if (v.type == type) {
						v.checked = true;
					} else {
						v.checked = false;
					}
				});
			}
		}
	};
</script>


