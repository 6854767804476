<template>
	<div id="cp_process">
		<!-- <div id="cp_issue" v-show="active == 1">
			<div class="postProduct_main">
				<div class="postProduct_box">
					<el-page-header @back="goBack" content="发布产品"></el-page-header>
					
				</div>
				<div>
					<el-steps :active="active" simple>
						<el-step title="第1步：选择产品分类"></el-step>
						<el-step title="第2步：产品参数、标题、详情等 "></el-step>
					</el-steps>
				</div>
			</div>
		</div> -->

		<div class="postProduct_main" >
			<div class="postProduct_main_tit-w">
				<el-page-header @back="goBack" content="发布产品"></el-page-header>
				
			</div>
			<div class="postProduct_main_form">
				<div class="postProduct_paramBox">
					
					<div class="Product_title">
						<div class="postProduct_textBox postProduct_textBox_name_special">
							<div class="postProduct_textBox_name">
								<em>*</em>
								产品标题：
							</div>
							<div class="postProduct_textBox_inputtxt">
								<input type="text" v-model="form.title" class="postProduct_textBox_titleinput" prompt="3-30字，请勿在标题中使用联系方式、疑问词、特殊符号等" />
							</div>
							<span class="input_txt_lj input_txt_lj1">
								<b>0</b>
								/30个汉字
							</span>
						</div>
						<div class="f_wrap_paramBox_con">
							<!--产品标题左S-->
							<div class="f_paramBox_con_left" style="margin-bottom:0;padding-bottom:0;">
								<!--非IE中的统计S-->
								<div class="f_con_left_block">
									<!-- <div class="f_t1">
										<el-progress type="circle" :percentage="25"></el-progress>
									</div>
									<p>标题完整度</p> -->
								</div>
							</div>
							<div class=" f_paramBox_con_right">
								<div class="f_cpbt">
									<p class="f_red">建议采用的标题结构：品牌+型号+具体参数+卖点特征+修饰用语+商品名称</p>
									<p>
										<strong>例：</strong>
										供应厂家直销2014新款特卖JINYU蕾丝拼接雪纺连衣裙
										<br />
										供应徽羚羊2人双层防风防雨独立内帐一秒快开野营帐篷
									</p>
									<p class="f_red">注意：请勿在标题中使用联系人、联系方式、疑问词、疑问语句，不要使用特殊字符，严禁堆砌关键词！</p>
								</div>
							</div>
						</div>
					</div>

					<div class="Product_title">
						<div class="postProduct_textBox postProduct_textBox_name_special">
							<div class="postProduct_textBox_name">
								<em>*</em>
								产品图片：
							</div>
							<div class="postProduct_textBox_tip">
								可同时上传4张图片（按Ctrl键多选），
								<span style="color: #e61b00;margin-right:10px;">图片长、宽均不低于240像素，大小大于25k。</span>
								图片严禁重复
							</div>
						</div>
						<div class="f_wrap_paramBox_con">
							<!--产品图片-->
							<div class="f_paramBox_con_left" style="margin-bottom:0;padding-bottom:0;">
								<div class="f_con_left_block">
									<!-- <div class="f_t1">
										<el-progress type="circle" :percentage="0"></el-progress>
									</div>
									<p>图片完整度</p> -->
								</div>
							</div>
							<div class=" f_paramBox_con_right">
								<el-upload
									:action="baseUrl + 'file/upload_one'"
									list-type="picture-card"
									:class="{ disabled: uploadDisabled }"
									:on-preview="handlePictureCardPreview"
									:file-list="cover_list"
									:before-remove="img_remove"
									:on-change="handleChange"
									:on-success="cover_success"
									:limit="limit"
									:data="{ dir: 'product' }"
								>
									<i class="el-icon-plus"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible"><img width="100%" :src="dialogImageUrl" alt="" /></el-dialog>
							</div>
						</div>
					</div>

					<div class="Product_title">
						<div class="postProduct_textBox postProduct_textBox_name_special">
							<div class="postProduct_textBox_name">
								<em>*</em>
								详情介绍：
							</div>
							<div class="postProduct_textBox_tip">无详情图时，文字不得少于500字，有详情图时，文字不得少于50字。</div>
						</div>
						<div class="f_wrap_paramBox_con">
							<!--产品图片-->
							<div class="f_paramBox_con_left">
								<div class="f_con_left_block">
									<!-- <div class="f_t1">
										<el-progress type="circle" :percentage="60"></el-progress>
									</div>
									<p>详情完整度</p> -->
								</div>
							</div>
							<div class=" f_paramBox_con_right" style="padding-right: 200px;">
								<vue-editor
									v-model="form.introduction"
									@focus="onEditorFocus"
									@blur="onEditorBlur"
									use-custom-image-handler
									@image-added="handleImageAdded"
									@selection-change="onSelectionChange"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="commodity">
				<el-form ref="form" :model="form" label-width="120px">
					<el-form-item label="产品标签:" :rules="[{ required: true }]">
						<el-tag :key="tag" v-for="tag in form.tag" closable :disable-transitions="false" @close="handleClose(tag)">{{ tag }}</el-tag>
						<el-input
							class="input-new-tag"
							v-if="inputVisible"
							v-model="inputValue"
							ref="saveTagInput"
							size="small"
							@keyup.enter.native="handleInputConfirm"
							@blur="handleInputConfirm"
						></el-input>
						<el-button v-else class="button-new-tag" size="small" @click="showInput">+标签</el-button>
					</el-form-item>
					<el-form-item label="商品售价:">
						<el-input type="number" v-model="form.price"></el-input>
						<span>&nbsp;&nbsp;/元</span>
					</el-form-item>
					
				</el-form>
			</div>

			<div class="postProduct_paramBox_con_checkbox">
				<input type="checkbox" value="" checked="checked" onclick="check_tongyi();" />
				<span class="linkBlue">同意《kejiniu产品信息发布规则》</span>
			</div>
			<p class="postProduct_btn"><button class="postProduct_postbtn" @click="submit()">立即发布</button></p>
			<!-- <div class="postProduct_percentage">
				<div class="f_t1">
					<el-progress type="circle" :percentage="100"></el-progress>
				</div>
				<strong>信息完整度</strong>
				<span>信息完整度越高，产品曝光率也越高，对产品排名及收录都有利哦</span>
			</div> -->
		</div>
	</div>
</template>

<script>
import { VueEditor } from 'vue2-editor';
export default {
	metaInfo: {
		title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
		meta: [
			{
				name: 'keywords',
				content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
			},
			{
				name: 'description',
				content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
			}
		]
	},
	components: {
		VueEditor
	},
	data() {
		return {
			form: {
				title: '',
				cover: [],
				tag: [],
				introduction: '',
				price: 0,
				minimum_purchase: 1,
				cate: { one_name: '', two_name: '' }
			},
			active: 1,
			activeName: 'first',
			contentBox: true,
			contextList: true,
			radio: '1',
			cate_show: -1,
			select: {
				one_name: '',
				two_name: ''
			},
			cate: {},
			cate_arr: [],
			minimum_purchase: '不限',
			dialogImageUrl: '',
			dialogVisible: false,
			limit: 4,
			uploadDisabled: false,
			inputVisible: false,
			inputValue: '',
			id: '',
			cover_list: []
		};
	},
	created() {
		console.log('creted');
	},
	beforeCreate() {
		console.log('beforeCreate');
	},
	mounted() {
		console.log('query', this.$route.query);
		if (this.$route.query != {} && typeof this.$route.query.id != 'undefined') {
			this.id = this.$route.query.id;
			this.get_detail();
		}
		this.get_cate();
		this.company_detail();
		/* 	if (JSON.parse(localStorage.getItem('product'+this.id))) {
				console.log('有缓存')
				this.form = JSON.parse(localStorage.getItem('product'+this.id))
			} else {
				
			} */

		/* console.log('params', this.$route.params)
			if (this.$route.params) {
				if (typeof(this.$route.params.id) != 'undefined') {
					this.id = this.$route.params.id;
					this.form = JSON.parse(localStorage.getItem('product'+this.id))
				}
				if (typeof(this.$route.params.cate.one_name) != 'undefined') {
					console.log("this.form",this.form)
					this.form.cate = this.$route.params.cate;
				}
			} */
		let cover_list = this.cover_list;
		this.form.cover.forEach(function(v) {
			cover_list.push({
				url: v
			});
		});
		// console.log('meta',this.$route.meta);
		/* if (this.$route.meta.select) {
				this.form.cate = this.$route.meta.select
			} */
		console.log(this.form.cate);
		if (this.form.minimum_purchase > 1) {
			this.minimum_purchase = '至少';
		}
		this.$forceUpdate();
	},
	beforeRouteLeave(to, from, next) {
		console.log('beforeRouteLeave', to, from, next);
		to.meta.select = this.form.cate;
		next();
	},
	methods: {
		company_detail(){
			this.$http_qm
				.post('company/detail', {
					type: 'me'
				}).then(res => {
					if (res.code == 200) {
						this.form.company_id = res.data.id;
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		handleImageAdded(file, Editor, cursorLocation, resetUploader) {
			var formData = new FormData();
			formData.append('file', file);
			formData.append('dir', 'company');
			this.$http
				.post('file/upload_one', formData, { headers: { 'Content-Type': 'multipart/form-data;' } })
				.then(res => {
					let url = res.data.img_url; // Get url from response
					Editor.insertEmbed(cursorLocation, 'image', url);
					resetUploader();
				})
				.catch(function(error) {
					console.log(error);
				});
		},
		next() {
			if (this.select.one_name == '' && this.form.cate.one_name == '') {
				this.$message.error('请先选择分类');
				return false;
			}
			this.active = 2;
		},
		show(index) {
			this.cate_show = index;
		},
		do_select(one_name, two_name) {
			this.form.cate.one_name = one_name;
			this.form.cate.two_name = two_name;
			this.$forceUpdate();
		},
		handleClick(tab, event) {
			console.log(tab, event);
		},
		get_cate() {
			this.$http_qm
				.post('product_cate/list', {})
				.then(res => {
					this.cate_arr = res.data;
				})
				.catch(err => {
					console.log(err);
				});
		},
		get_detail() {
			if (this.id == '') {
				return false;
			}
			this.$http_qm
				.post('product/detail', {
					id: this.id
				})
				.then(res => {
					this.form = res.data;
					if (this.form.cate == null) {
						this.form.cate = { one_name: '', two_name: '' };
					}
					if (this.form.cover == null) {
						this.form.cover = [];
					} else {
						let cover_list = this.cover_list;
						this.form.cover.forEach(function(v) {
							cover_list.push({
								url: v
							});
						});
					}
					if (this.form.tag == null) {
						this.form.tag = [];
					}
					if (this.form.minimum_purchase == 0) {
						this.form.minimum_purchase = 1;
					}
					localStorage.setItem('product' + this.id, JSON.stringify(this.form));
					this.$forceUpdate();
				})
				.catch(err => {
					console.log(err);
				});
		},
		minimum_purchase_change(v) {
			if (v == '不限') {
				this.form.minimum_purchase = 1;
			} else {
				this.minimum_purchase = v;
			}
		},
		submit() {
			if (this.id != '') {
				var post_url = 'product/update';
			} else {
				post_url = 'product/add';
			}
			this.form.price = parseFloat(this.form.price);
			this.form.minimum_purchase = parseFloat(this.form.minimum_purchase);
			this.$http_qm
				.post(post_url, this.form)
				.then(res => {
					this.$message.info(res.msg);
					if (res.code == 200) {
						this.$router.push({ path: '/company/cp_admin' });
					}
				})
				.catch(err => {
					console.log(err);
				});
		},
		img_remove(file) {
			let path = file.response.data.img_url;
			let index = this.form.cover.indexOf(path);
			this.form.cover.splice(index, 1);
			this.remove(path);
		},
		remove(path) {
			this.$http_qm
				.post('upload/remove', {
					path: path
				})
				.then(res => {
					this.$message.info(res.msg);
				})
				.catch(err => {
					console.log(err);
				});
		},
		cover_success(res) {
			if (res.code == 200) {
				this.form.cover.push(res.data.img_url);
			}
		},
		// 图片上传
		handleChange(file, fileList) {
			if (fileList.length >= 4) {
				this.uploadDisabled = true;
			}
		},
		handleRemove(file, fileList) {
			if (fileList.length <= 1) {
				this.uploadDisabled = false;
			}
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		// 编辑器
		onEditorBlur(quill) {
			console.log('editor blur!', quill);
		},
		onEditorFocus(quill) {
			console.log('editor focus!', quill);
		},
		onSelectionChange(range) {
			console.log('selection change!', range);
		},
		// 产品标签
		handleClose(tag) {
			this.form.tag.splice(this.form.tag.indexOf(tag), 1);
		},
		showInput() {
			this.inputVisible = true;
			this.$nextTick(() => {
				this.$refs.saveTagInput.$refs.input.focus();
			});
		},
		handleInputConfirm() {
			let inputValue = this.inputValue;
			if (inputValue) {
				this.form.tag.push(inputValue);
			}
			this.inputVisible = false;
			this.inputValue = '';
		},
		toUrl(url) {
			this.$router.push({
				path: url
			});
		},
		goBack() {
			this.$router.go(-1);
		}
	}
};
</script>

<style lang="less" src="../../assets/css/firm.less"></style>
