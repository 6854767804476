<template>
	<div id="zc_password">
		<div class="wrapper clearfix">
			<div class="main_left">
				<Left></Left>
			</div>
			<div class="content">
				<div class="page_nav clearfix">
					<div class="page_nav_tit">账号管理</div>
				</div>
				<el-tabs type="border-card">
					<el-tab-pane label="修改密码">
						<div class="change_pw" style="display: block;">
							<div class="attentions">您正在为登录的帐号 <span class="username">{{user.username}}</span>修改密码!</div>
							<table frame='void' rules='none' class="change_pw_table" width="100%" cellspacing="0" border="0">
								<tbody>
									<tr>
										<td class="tar" width="120">请输入当前密码</td>
										<td>
											<input type="password"  v-model="add.password" class="input_text w200" id="opassword" name="opassword"><span id="opassword_tips"></span>
										</td>
									</tr>
									<tr>
										<td class="tar" width="120">新密码</td>
										<td>
											<input type="password"  v-model="add.new_password"  class="input_text w200" id="password" name="password"><span id="password_tips"></span>
										</td>
									</tr>
									<!-- <tr>
										<td class="tar" width="120">当前密码强度</td>
										<td>
											<div id="pw_intensity" class="">
												<span class="pw_intensity_low"></span>
												<span class="pw_intensity_middle"></span>
												<span class="pw_intensity_high"></span>
											</div>
										</td>
									</tr> -->
									<tr>
										<td class="tar" width="120">再次输入新密码</td>
										<td>
											<input type="password" v-model="add.new_password1" class="input_text w200" id="rePassword" name="rePassword"><span id="rePassword_tips"></span>
										</td>
									</tr>
									<tr>
										<td width="120"></td>
										<td>
											<a class="s_btn_co mr20" id="btn_pw_confirm"><span @click="edit()">确定</span></a>
											<a class="s_btn_op" id="btn_pw_cancel" @click="reset()"><span>取消</span></a>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</el-tab-pane>
					<!-- <el-tab-pane label="账号绑定">
						<div class="bind_account" style="display: block;">
							<div class="bind_weixin com">
								<div id="weixin_have" style="display: none;">
									<p class="bind_on">您已绑定微信,可以直接使用微信登录</p>
									<p class="bind_cancel"><a href="#">取消绑定</a></p>
								</div>
								<div id="weixin_no">
									<p class="bind_off">您还未绑定微信</p>
									<p class="bind_now">
										<span>立即绑定</span>
									</p>
								</div>
							</div>
						</div>
					</el-tab-pane> -->
				</el-tabs>
			</div>
		</div>
	</div>
</template>

<script>
	import Left from "./Left.vue"
	import {
		mapState
	} from 'vuex';
	export default {
		metaInfo: {
			title: '科技牛—为企业精准匹配科研专家以及提供技术解决方案的服务平台',
			meta: [{
					name: 'keywords',
					content: '技术对接,技术交易,技术对接平台,技术创新服务,技术转移,成果转化,技术推广,解决方案'
				},
				{
					name: 'description',
					content: '科技牛是一家专业为企业提供四技服务:技术开发、技术转让、技术咨询、技术服务，精准匹配科研专家及技术解决方案平台公司'
				}
			]
		},
		components: {
			Left
		},
		computed: {
			...mapState(['user'])
		},
		data() {
			return {
				add: {
					password: '',
					new_password: '',
					new_password1: '',
				}
			}
		},
		methods: {
			reset(){
				this.add = {};
			},
			edit() {
				this.$http.post('user/modify_pwd', this.add).then(res => {
					this.$message.info(res.msg)
				}).catch(err => {
                  console.log(err)
				})
			}
		}
	}
</script>

<style lang="less" src="../../assets/css/expert.less"></style>
