<template>
	<div id="service">
		<div class="banner ">
			<h1 class="text-center text-white">企业专业服务</h1>
			<h2 class="text-center text-white">提升企业创新力量与产品核心竞争力</h2>
			<div class="box1 grid">
				<div class="li flex space-between" v-for="(v,k) in box1_data" :key="k">
					<div class="col">
						<div class="title">
							{{v.title}}
						</div>
						<div class="des">
							{{v.des}}
						</div>
					</div>
					<img :src="v.img">
				</div>
			</div>
		</div>

		<div class="box2 min_width hidden">
			<h1 class="text-center">技术需求对接服务</h1>
			<div class="row2 text-center">
				企业技术开发、技术咨询、技术服务、技术升级、新产品开发、技术转移、成果转化、产学研合作技术难题攻关、委托（联合）开发、技术成果对接、科技成果评价、技术（产品）检测（认证）、大型仪器共享、共建院士专家工作站和科技工作站，实现产学研有效合作与对接。
			</div>
			<div class="row3 grid">
				<!-- v-for="(v,k) in 5" :key="k" -->
				<div class="li">
					<div class="bg">
						<img src="../../assets/img/service/img6.png" alt="">
					</div>
					<div class="title">技术对接</div>
					<div class="des">
						专家上门 <br />
						一站式对接服务
					</div>
				</div>
				<div class="li">
					<div class="bg">
						<img src="../../assets/img/service/img2.png" alt="">
					</div>
					<div class="title">成果推送</div>
					<div class="des">
						成果精准分析 <br />
						每年六次推荐
					</div>
				</div>
				<div class="li">
					<div class="bg">
						<img src="../../assets/img/service/img1.png" alt="">
					</div>
					<div class="title">成果路演</div>
					<div class="des">
						成果路演 <br />
						每月一至两次
					</div>
				</div>
				<div class="li">
					<div class="bg">
						<img src="../../assets/img/service/img5.png" alt="">
					</div>
					<div class="title">科技创新大会</div>
					<div class="des">
						顶级专家分享前沿趋势 <br />
						每年两次
					</div>
				</div>
				<div class="li">
					<div class="bg">
						<img src="../../assets/img/service/img6.png" alt="">
					</div>
					<div class="title">共享专家</div>
					<div class="des">
						20万+专家 <br />
						精准匹配您的需求
					</div>
				</div>
			</div>

		</div>
		<div class="box3 atuo hidden">
			<h1 class="text-center">找专家</h1>
			<div class="row2 text-center">
				一站式的技术创新对接服务 ，汇聚20万+科研专家，标准化服务流程，用“更专业、更高效”的方式助推企业技术升级转型。
			</div>
			<div class="row3 grid">
				<div class="li" @click="href('https://www.keji6.cn/expert')" v-for="(v,k) in expert_data" :key="k"
					:style="'background:url('+v.bg+') no-repeat center;'">
					<div class="text"> {{v.text}}</div>
				</div>
			</div>
		</div>
		<div class="box4 atuo hidden">
			<h1 class="text-center text-white">知产规划</h1>
			<div class="text-center font-16 text-white ">
				为企业生产经营化解各类知识产权危机、防范多重知识产权风险、建立完善知识产权布局，让知识产权发挥真正价值。
			</div>
			<div class="row3 grid">
				<div class="li" @click="href(v.href)" v-for="(v,k) in zhichan_data" :key="k">
					<img :src="v.img" alt="">
					<div class="title">
						{{v.text}}
					</div>
				</div>
			</div>
		</div>
		<div class="box5 atuo hidden">
			<h1 class="text-center">政策申报</h1>
			<h4 class="text-center font-16">科技政策规划师一对一辅导创新资金申报 </h4>
			<div class="row1">
				<div class="col1">
					<div class="li" v-for="(v,k) in zhengce_data" @click="href(v.href)" :key="k">
						<div class="icon fl">

						</div>
						<div class="col2">
							<div class="title">{{v.text1}}</div>
							<div class="des">
								{{v.text2}}
							</div>
						</div>
					</div>
				</div>
				<div class="col2">
					<img src="../../assets/img/service/img17.png">
				</div>
			</div>
		</div>
		<div class="box6  atuo hidden">
			<div class="btn" @click="href('https://www.chengzijianzhan.com/tetris/page/6908890074797637640/')">
				<img src="../../assets/img/service/img10.png">
			</div>

		</div>
		<div class="box7  atuo hidden">
			<h1 class="text-center">标准化服务流程</h1>
			<div class="row1">
				<div class="li flex-row" v-for="(v,k) in service_process_data" :key="k">
					<div class="col1">
						<div class="bg">
							<img :src="v.img">
						</div>
						<div class="title">
							{{v.text}}
						</div>
					</div>
					<img v-if="k<=4" class=" col2" src="../../assets/img/service/icon15.png">
					<img v-else-if="k==5" class=" six" src="../../assets/img/service/icon10.png">
					<template v-else-if="k==11"></template>
					<img v-else class="col2" src="../../assets/img/service/icon14.png">
				</div>

			</div>
		</div>
		<div class="box8 atuo hidden">
			<h1 class="text-center">案例</h1>
			<div class="flex">
				<div class="left_btn"><i class="el-icon-arrow-left" @click="go_left()"></i></div>
				<el-carousel ref="case" position="none" name="case_index" :autoplay="false">
					<el-carousel-item v-for="(v,k) in case_data" :key="k">
						<div class="flex li">
							<div class="col1">
								<el-image :src="v.img" fit="fill"></el-image>
							</div>
							<div class="col2">
								<div class="title">
									{{v.title}}
								</div>
								<div class="des">
									{{v.des}}
								</div>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="right_btn" @click="go_right()"><i class="el-icon-arrow-right"></i></div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		metaInfo: {
			title: '科技牛—企业专业服务—— 提升企业创新力量与产品核心竞争力',
			meta: [{
					name: 'keywords',
					content: '企业创新，技术对接，技改支持，技术创新服务'
				},
				{
					name: 'description',
					content: '科技牛技术创新顾问为企业提供一站式对接服务，链接专家成果，助力企业创新。'
				}
			]
		},
		data() {
			return {
				box1_data: [{
						title: '需求对接',
						des: '金牌项目经理对接企业技术需求',
						img: require("../../assets/img/service/img14.png")
					},
					{
						title: '知产规划',
						des: '根据企业目标制定解决方案，通过知识产权来监管执行保护盈利。',
						img: require("../../assets/img/service/img18.png")
					},
					{
						title: '找专家',
						des: '汇聚20万+科研专家，为您产品创新保驾护航',
						img: require("../../assets/img/service/img15.png")
					},
					{
						title: '政策申报',
						des: '科技政策规划师一对一辅导创新资金申报',
						img: require("../../assets/img/service/img16.png")
					},
				],
				expert_data: [{
						bg: require('../../assets/img/service/img7.jpg'),
						text: '技术攻坚专家'
					},
					{
						bg: require('../../assets/img/service/img9.jpg'),
						text: '科研团队'
					},
					{
						bg: require('../../assets/img/service/img3.jpg'),
						text: '高校合作'
					}
				],
				zhichan_data: [{
					img: require('../../assets/img/service/img12.jpg'),
					text: '商标',
					href: 'https://www.chengzijianzhan.com/tetris/page/6911920390722879499/'
				}, {
					img: require('../../assets/img/service/img20.jpg'),
					text: '专利',
					href: 'https://www.chengzijianzhan.com/tetris/page/6911920390722879499/'
				}, {
					img: require('../../assets/img/service/img11.jpg'),
					text: '软著',
					href: 'https://www.chengzijianzhan.com/tetris/page/6911920390722879499/'
				}],
				zhengce_data: [{
						text1: '高新技术企业认定',
						text2: '一站式服务  为企业量身定制项目申报服务',
						href: 'https://www.keji6.cn/soar',
					},
					{
						text1: '知识产权贯标',
						text2: '政府资金扶持最高30W',
						href: 'http://www.yishiip.cn/col.jsp?id=110',
					},
					{
						text1: '双软认定',
						text2: '享政策税收优惠  增值税即征即退',
						href: 'http://www.yishiip.cn/col.jsp?id=109',
					},
					{
						text1: '科技小巨人',
						text2: '享受高达500W拨款补贴',
						href: 'http://www.yishiip.cn/col.jsp?id=108',
					},
					{
						text1: '高新技术成果转化',
						text2: '城市落户 职称评审',
						href: 'http://www.yishiip.cn/col.jsp?id=107',
					},
				],
				service_process_data: [{
					img: require('../../assets/img/service/icon3.png'),
					text: '登录平台'
				}, {
					img: require('../../assets/img/service/icon8.png'),
					text: '提交需求'
				}, {
					img: require('../../assets/img/service/icon12.png'),
					text: '需求分析'
				}, {
					img: require('../../assets/img/service/icon9.png'),
					text: '委托确认'
				}, {
					img: require('../../assets/img/service/icon5.png'),
					text: '精准匹配'
				}, {
					img: require('../../assets/img/service/icon3.png'),
					text: '三方会谈'
				}, {
					img: require('../../assets/img/service/icon4.png'),
					text: '共享收益'
				}, {
					img: require('../../assets/img/service/icon2.png'),
					text: '成果转化'
				}, {
					img: require('../../assets/img/service/icon1.png'),
					text: 'IP布局'
				}, {
					img: require('../../assets/img/service/icon11.png'),
					text: '项目交付'
				}, {
					img: require('../../assets/img/service/icon13.png'),
					text: '研发管控'
				}, {
					img: require('../../assets/img/service/icon6.png'),
					text: '确定合作模式'
				}, ],
				case_data: [{
					img: require('../../assets/img/service/case1.png'),
					title: '红外焊接项目',
					des: '企业在红外焊接领域遇到技术难题，一实科技通过文献分析、技术成果挖掘、评估等工作，发现中科院物技所在红外控制方面有独特的技术，和企业的需求相匹配。三方针对技术开发和合作方式沟通交流，由一实科技负责知识产权作价入股事宜,设计股权架构并组建新公司，目前该公司注册资本1000万，已和宁波均胜集团、3M等公司有深度合作。'
				}, {
					img: require('../../assets/img/service/case2.png'),
					title: '脐带血间充质干细胞项目',
					des: '一实科技牵头,整合技术方、资本方共同投资孵化了一家生命科技有限公司。治疗方向：亚健康、面部美容，骨关节修复，勃起功能障碍，薄型子宫内膜，NK细胞免疫疗法，目前产品已在市场销售。'
				}, {
					img: require('../../assets/img/service/case3.png'),
					title: '凹版印刷环保油墨项目',
					des: '一实科技受专家方委托推广“凹版印刷LED-UV油墨”技术成果，寻找市场应用空间、产品产业化。经过一实科技多方调研，成功匹配到了某公司环保油墨项目需求，油墨样品已完成上机测试。'
				}, ]
			}
		},
		mounted() {

		},
		methods: {
			web_href(url) {
				window.open(url)
			},
			href(url) {
				window.open(url)
			},
			go_left() {
				this.$refs.case.prev();
			},
			go_right() {
				this.$refs.case.next();
			}
		}
	}
</script>

<style lang="less">
	@font-face {
		font-family: "Source-Han-Sans";
		src: url(../../assets/font/SourceHanSansCN-Normal.ttf);
	}

	.text-white {
		color: #FFFFFF;
	}

	.font-16 {
		font-size: 16px;
	}

	.auto {
		margin: auto;
	}

	.hidden {
		overflow: hidden;
	}

	#service {
		width: 100%;
		overflow-y: hidden;
		min-width: 1400px;
		margin: auto;

		h1 {
			font-size: 32px;
			font-family: Source-Han-Sans;
			font-weight: bold;
			color: #333333;
			padding: 85px 0px 35px;
		}

		.min_width {
			width: 1120px;
			margin: auto;
		}

		.banner {
			width: 100%;
			position: relative;
			height: 900px;
			background: url(../../assets/img/service/img13.jpg) no-repeat top center;

			h1 {
				font-size: 52px;
				padding-top: 222px;
				margin: 0px;
				color: #FFFFFF;
			}

			h2 {
				font-size: 42px;
				margin-top: 10px;
			}
		}

		.box1 {
			width: 1120px;
			margin: 179px auto 0px;
			position: relative;
			background: #FFFFFF;
			box-shadow: -1px 20px 40px 0px rgba(20, 34, 57, 0.1);
			border-radius: 10px;
			grid-template-columns: repeat(2, 1fr);

			.li {
				padding: 59px 27px 55px 40px;
				border: 1px solid #F2F2F2;
			}

			.title {
				font-size: 28px;
				font-family: Source-Han-Sans;
				font-weight: bold;
				color: #333333;
			}

			.des {
				font-size: 18px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #666666;
				margin-top: 27px;
			}
		}

		.box2 {
			margin-top: 220px;
			text-align: center;

			.row2 {
				margin: 0px auto 48px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #666666;
				line-height: 24px;
				font-size: 16px;
			}

			.row3 {
				grid-template-columns: repeat(5, 1fr);

				.bg {
					width: 142px;
					height: 142px;
					margin: 0px auto 19px;
					background: #5069FF;
					border-radius: 50%;
					display: flex;

					img {
						margin: auto;
					}
				}

				.title {
					font-size: 20px;
					font-family: Source-Han-Sans;
					font-weight: bold;
					color: #333333;
					line-height: 40px;
				}

				.des {
					font-size: 16px;
					font-family: Source-Han-Sans;
					font-weight: 500;
					color: #666666;
					line-height: 24px;
				}
			}
		}

		@keyframes expert {
			0% {

				margin-top: -100px;
			}


			50% {

				margin-top: 100px;
			}

			100% {

				margin-top: auto;
			}
		}

		.box3 {
			overflow: hidden;

			.row2 {
				font-size: 16px;
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #666666;
				line-height: 24px;
			}

			.row3 {
				grid-template-columns: repeat(3, 1fr);
				width: 1119px;
				margin: 45px auto 45px;

				.li {
					overflow: hidden;
					display: flex;
					justify-content: center;
					height: 280px;
					cursor: pointer;

					&:hover .text {
						animation: bounce 1s;
					}

					.text {
						margin: auto;
						font-size: 24px;
						font-family: Source-Han-Sans;
						font-weight: bold;
						color: #FFFFFF;
						line-height: 71px;
					}
				}
			}
		}

		.box4 {
			width: 100%;
			height: 580px;
			padding: 0px 0px 34px;
			background: url(../../assets/img/service/img19.png) #4F8DFF no-repeat center top;

			h1 {
				padding: 42px 0px 20px;
				color: #FFFFFF
			}

			.row3 {
				width: 1036px;
				margin: 42px auto 34px;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				grid-column-gap: 86px;

				.li {
					width: 288px;
					cursor: pointer;
				}

				.title {
					padding: 22px 0px;
					background-color: #FFFFFF;
					font-size: 24px;
					font-family: Source-Han-Sans;
					font-weight: 500;
					color: #333333;
					text-align: center;
				}
			}
		}

		.box5 {
			h4 {
				font-family: Source-Han-Sans;
				font-weight: 500;
				color: #666666;
				line-height: 24px;
			}

			.row1 {
				width: 880px;
				margin: 30px auto 0px;
				display: flex;
				justify-content: space-between;

				.icon {
					width: 22px;
					height: 22px;
					background: #5069FF;
					transform: rotate(45deg);
					margin: 10px 12px 0px 0px;
				}

				.li {
					margin-top: 33px;
					display: flex;
					cursor: pointer;

					.title {
						font-size: 24px;
						font-family: Source-Han-Sans;
						font-weight: bold;
						color: #333333;
					}

					.des {
						font-size: 16px;
						font-family: Source-Han-Sans;
						font-weight: 500;
						color: #666666;
					}
				}
			}
		}

		.box6 {
			width: 100%;
			height: 240px;
			position: relative;
			margin-top: 80px;
			background: url(../../assets/img/service/img4.png) center top;

			.btn {
				position: relative;
				left: 65%;
				top: 90px;
				cursor: pointer;
			}
		}

		.box7 {
			background: url(../../assets/img/service/img21.png) no-repeat center top;
			width: 100%;
			text-align: center;
			padding-bottom: 10px;

			h1 {
				padding: 60px 0px 0px;
			}

			.row1 {
				width: 1120px;
				margin: auto;
				display: flex;
				flex-wrap: wrap;
				align-items: center;

				.li {
					align-items: center;
					display: flex;
					margin: 25px 0px;
					position: relative;

					.col1 {
						.bg {
							width: 110px;
							height: 110px;
							transform: rotate(45deg);
							background: #FFFFFF;
							box-shadow: -1px 10px 18px 0px rgba(0, 38, 109, 0.1);
							border-radius: 20px;
							display: flex;
							position: relative;
							top: 15px;

							img {
								transform: rotate(-45deg);
								margin: auto;
							}
						}

						.title {
							margin-top: 35px;
							font-size: 20px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #5069FF;
						}
					}

					.col2 {
						margin: -20px 35px 0px;

					}

					.six {
						position: relative;
						top: 110px;
						left: -65px;
					}
				}
			}

			h1 {}
		}

		.box8 {

			margin: 0px auto 30px;
			overflow-x: hidden;
			width: 1100px;

			.left_btn {
				margin-top: 120px;
				font-size: 43px;
				cursor: pointer;
			}

			.right_btn {
				margin-top: 120px;
				font-size: 43px;
				cursor: pointer;
			}

			.el-carousel {

				width: 1000px;
				margin: auto;
				padding: 30px;

				.el-carousel__container {
					padding: 35px 37px;
					border-radius: 10px;
					box-shadow: 3px 14px 26px 1px rgba(6, 19, 51, 0.1);
					margin: auto;
					height: 182px;
					overflow: hidden;

					button {
						display: none;
						z-index: 10;
					}

					/* .el-carousel__arrow--left {

					}

					.el-carousel__arrow--right {

					} */
				}

				.el-carousel__item {
					height: 183px;
					background: #FFFFFF;
					overflow: hidden;

				}

				.li {
					background: #FFFFFF;
					padding: 35px 37px;

					.col1 {
						width: 248px;
						height: 182px;

						img {
							width: 248px;
							height: 182px;
						}

						margin-right: 31px;
					}

					.col2 {
						width: 600px;

						.title {
							font-size: 24px;
							font-family: Source-Han-Sans;
							font-weight: bold;
							color: #222222;
							line-height: 40px;
						}

						.des {
							margin-top: 5px;
							font-size: 16px;
							line-height: 21px;
							font-family: Source-Han-Sans;
							font-weight: 500;
							color: #222222;
						}
					}
				}
			}
		}
	}
</style>
